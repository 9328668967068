import React, { useRef } from 'react';
import MUIAutocomplete from '@mui/material/Autocomplete';
import { NMCKEditor } from "../CKEditor";
import { Form as AntForm, Select } from "antd";
// import { Select, MenuItem } from '@mui/material';
import { Radio } from 'antd';
import { Button } from "../Button";
import "./style.scss"

const InputContainer = (props) => {
  return (
    <div className={`container ${props.className || ''} ${props.readOnly ? 'readOnly' : ''}  ${props.disabled ? 'disabled' : ''} ${props.error ? 'error' : ''}`} ref={props.InputPropsRef}>
      {props.children}
    </div>
  )
}

export const AutoComplete = (props) => {
  const { label, disablePortal, options, placeholder, id, name, value, disabled, readOnly, className, helpText, helpTextClassName, containerParentClassName, inputContainerClassName, labelClassName, labelFontSize, ...rest } = props;
  const direction = rest.direction || 'row'
  const _ref = useRef(null)
  const removeFocus = () => {
    if (_ref && _ref.current) {
      const input = _ref.current.querySelector('input')
      if (input) input.blur();
    }
  }
  return (
    <div className={`font-input ${rest.required ? 'req' : ''} ${direction} ${className || ''} ${readOnly ? 'readOnly' : ''}`}>
      <div className='row label-cont'>
        <label className={`label ${labelFontSize ? labelFontSize : 'f9'} c00085 reg d-flex h-btn  ${labelClassName ? labelClassName : ''}`} htmlFor={id || name}>
          <div>
            {label}<span>{rest.showColon && <span className='f9 c00085 mright'>:</span>}</span>
            <span>{rest.required && <span className='astrick'>&nbsp;</span>}</span>
          </div>
        </label>
      </div>
      <MUIAutocomplete
        openOnFocus
        ref={_ref}
        disablePortal={disablePortal !== undefined ? disablePortal : undefined}
        id={id || name}
        value={value}
        options={options || []}
        onChange={(e, value) => {
          e.preventDefault();
          rest.onChange && rest.onChange({ target: { name: name, value: value } })
          removeFocus()
        }}
        classes={{ root: 'col f-rest', listbox: 'dropdown-list', option: "f9 option capital", groupLabel: 'f9 c00085 caps', noOptions: 'f9' }}
        disabled={disabled || readOnly}
        noOptionsText='No Matching Result'
        renderInput={(params) => {
          const { className, ..._rest } = params.inputProps
          return (
            <div className={`col f-rest w-100 ${containerParentClassName || ''}`}>
              <InputContainer InputPropsRef={params.InputProps.ref} className={`row ${inputContainerClassName || ''}`} readOnly={readOnly}>
                <input id={`${id || ''}_input`} type='text' placeholder={placeholder || label} className={`f9 input exo2 ${className}`} {..._rest} name={rest.name} />
                {!readOnly && !props.hideIcon && <i className='icon-profile-caret c00025 f12' />}
                {!readOnly && props.searchIcon && <i className='icon-search c00025 f10' />}
              </InputContainer>
              {Boolean(helpText) && <h6 className={`help-error help-text ${helpTextClassName}`}>{helpText}</h6>}
            </div>
          )
        }}
      />
    </div>
  )
}

export const TextInput = (props) => {
  const {
    label, type, required, id, name, value, disabled, className, font, showColon, readOnly, radioButtons, radioHints, error,
    helpText, helpTextClassName, editorType, onInstanceReady, containerParentClassName, inputContainerClassName, allowAddVariable, validationError, labelClassName, ...rest
  } = props
  const direction = rest.direction || 'row';
  const isTextArea = type === 'textarea';
  const radio = type === 'radio';
  const html = type === 'html';
  const file = type === 'file';
  const _ref = useRef(null);

  return (
    <div
      className={`col font-input text-input ${direction} ${required ? 'req' : ''} v-start ${className || ''} ${radio ? 'v-start radio-cont' : ''} ${readOnly ? 'readOnly' : ''} ${name || ''}`}>
      <div className={`row label-cont ${props.labelwidth || ''}`}>
        {
          Boolean(label) &&
          <label className={`${font || 'f9'} c00085 label  d-flex h-btn ${labelClassName ? labelClassName : ''}`} htmlFor={`${id || name}_input`}>
            <div className={`${props.labelTextClassName || ''}`}>
              {label}<span>{showColon && <span className={`${font || 'f9'} c00085`} >:</span>}{required && <span className='astrick'>&nbsp;</span>}</span>
            </div>
            {validationError && <div className="f11 cFE3333 help-error error">{validationError}</div>}

          </label>
        }
      </div>
      <div className={`col f-rest w-100 ${file ? 'o-hide' : ''} ${containerParentClassName || 'container-parent'}`} style={{ position: "relative" }}>
        <InputContainer className={`col w-100 ${inputContainerClassName || ''} ${html ? 'html' : ''}`} readOnly={readOnly} disabled={disabled} error={error} >
          {
            isTextArea ?
              <React.Fragment>
                {
                  readOnly ?
                    <p className={`${font || 'f9'} input`}>{value}</p>
                    :
                    <textarea id={`${id || name}_input`} name={name} className={`${font || 'f9'} input`} {...rest} value={value} disabled={disabled || readOnly}></textarea>
                }
                {Boolean(rest.maxLength) && <h6 className='f9 c00025 reg count line-22'>{(value || '').length}/{rest.maxLength}</h6>}
              </React.Fragment>
              : radio ?
                <React.Fragment>
                  <Radio.Group value={value} onChange={rest.onChange} className={className} name={name} >
                    {
                      (radioButtons || []).map((radio) => {
                        return <Radio value={radio.value} key={radio.value}>{radio.label}</Radio>
                      })
                    }
                  </Radio.Group>
                  {
                    Boolean(radioHints && radioHints[value]) && <h6 className='hint f9 c777777'>{radioHints[value]}</h6>
                  }
                </React.Fragment>
                :
                html ?
                  <React.Fragment>
                    {
                      (readOnly || disabled) ?
                        <p className='ck-container max oy-auto default' dangerouslySetInnerHTML={{ __html: value }}></p>
                        :
                        <NMCKEditor
                          backgroundColor={rest.backgroundColor}
                          placeholder={rest.placeholder}
                          type={editorType}
                          initialData={value}
                          onChange={(e) => {
                            const editor = e.editor
                            if (typeof rest.onChange === "function" && editor) {
                              rest.onChange({
                                target: {
                                  name: name,
                                  value: editor.getData(),
                                  type: type
                                }
                              })
                            }
                          }}
                          onInstanceReady={onInstanceReady}
                          allowFontSize={rest.allowFontSize}
                        />
                    }
                  </React.Fragment>
                  :
                  file ?
                    <div className='row w-100 h-btn'>
                      <div className={`col f-rest o-hide nowrap ${font || 'f9'} ${value ? '' : 'c00045'}`} dangerouslySetInnerHTML={{ __html: value ? value.name : rest.placeholder }} ></div>
                      <Button label={rest.selectLabel} onClick={() => _ref.current && _ref.current.click()} />
                      <input type='file' accept={rest.accept} hidden ref={_ref} onChange={rest.onChange} disabled={readOnly || disabled} id={`${id || name}_input`} name={name} />
                    </div>
                    :
                    <input disabled={readOnly || disabled} id={`${id || name}_input`} type={type || 'text'} name={name} className={`${font || 'f9'} input exo2`} {...rest} value={value} />
          }

        </InputContainer>
        {Boolean(error) && <h6 className='f11 cFE3333 help-error error '>{error}</h6>}
        {Boolean(helpText) && <h6 className={`help-error help-text ${helpTextClassName}`}>{helpText}</h6>}
        {
          Boolean(allowAddVariable) &&
          <Button
            className="bg59A7AC add-var-btn"
            label="Add Variable"
            onClick={() => {
              console.log(props);
              rest.onAddVariable && rest.onAddVariable()
            }}
          />
        }
      </div>
    </div>
  )
}
export const Dropdown = (props) => {
  const { value, label, id, font, name, className, primary, caretClassName, helpTextClassName, helpText, readOnly, onChange, ...rest } = props
  const handleChange = (selectedVal) => {
    onChange && onChange({ target: { name: name, value: selectedVal } })
  }
  return (
    <AntForm.Item
      label={label}
      htmlFor={id || name}
      className={`drop-down ${className || ''}`}
      help={<span className={`help-text c00085 f10 ${helpTextClassName || ''}`}>{helpText}</span>}>
      <Select
        value={value}
        disabled={readOnly}
        id={id || name}
        className={`select ${readOnly ? 'readOnly' : ''}`}
        onChange={handleChange}
        {...rest}
      />
    </AntForm.Item>
  )
}
/*
<Select
      label={label} id={id || name} name={name} disabled={disabled} classes={{ select: 'select' }}
      IconComponent={() => <i className={`icon-profile-caret f11 caret ${caretClassName || 'c00085'}`} />}
      className={`dropdown ${className || ''}  ${primary && 'primary'} ${readOnly ? 'readOnly' : ''}`} variant='outlined' {...rest}>
      {
        (options || []).map((option) => {
          return (
            <MenuItem key={option.value} value={option.value} className={`dropdown-item c0085  ${primary && 'primary'}`}>
              <div className='row'>
                {Boolean(option.icon) && <i className={`icon ${font || 'f9'} ${option.icon}`} />}
                <h6 className={`label ${font || 'reg f9'}`}>{option.label}</h6>
              </div>
            </MenuItem>
          )
        })
      }
    </Select>
*/
export const Form = (props) => {
  const { Fields, formData, onChange, getOptions, fieldClass, error, onInstanceReady, onAddVariable, validationError } = props;
  const handleChange = (e) => {
    onChange && onChange(e)
  }
  const getValue = (val) => {
    if (val === undefined || val === null || val === '') {
      val = ''
    }
    return val;
  }
  return (
    Fields.map(({ attribute, className, ...rest }) => {
      const p = {
        value: getValue(formData[attribute]), key: attribute, error: error && error[attribute],
        className: `${fieldClass || 'w-100'} ${className || ''}`, name: attribute, onChange: handleChange,
        onInstanceReady: (typeof onInstanceReady === "function") ? onInstanceReady.bind(null, attribute) : onInstanceReady,
        onAddVariable: (typeof onAddVariable === 'function') ? onAddVariable.bind(null, attribute) : onAddVariable, validationError: attribute === 'trigger_after' ? validationError : null,
        ...rest
      }
      return (
        rest.type === 'select' ?
          <AutoComplete
            {...p}
            options={getOptions(attribute)} />
          :
          <TextInput {...p} />
      )
    })
  )
}