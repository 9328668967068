import React from 'react';
import { useLocation, } from "react-router-dom";
import { ModuleInnerNavBar, ModuleInnerHorizontalNavBar } from "../../../components";
import "./style.scss";

const EducatorNavs = [
  { icon: 'icon-learning f7', link: 'L', tooltip: "Learning" },
  { icon: 'icon-circular f7', link: 'C', tooltip: "Circular", },
  { icon: 'icon-evaluation f7', link: 'A', tooltip: "Assessment" },
]
export const EducatorControllerHomeScreen = (props) => {
  const { pathname } = useLocation();
  return (
    // <div className='row w-100 h-100 o-hide'>
    //   <ModuleInnerNavBar pathname={pathname} Items={EducatorNavs} />
    //   <div className='col f-rest h-100 o-hide bgWhite'>
    //     {props.children}
    //   </div>
    // </div>
    <div className=' w-100 h-100 o-hide d-flex flex-col bgWhite'>
      <div className=' w-100 o-hide bgWhite mb1'>
        <ModuleInnerHorizontalNavBar pathname={pathname} Items={EducatorNavs} />
      </div>
      <div className=' w-100 f-rest h-100 o-hide bgWhite'>
        {props.children}
      </div>
    </div>
  )
}