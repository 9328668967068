import { put, takeEvery, select } from "redux-saga/effects";
import { SagaRegistry, setAlertAndLoading, axios, requestHandler, getDateInFormat, getUserDetails } from "../app";
import { ContinuerActions } from "./slice";
import { getDepartments, getDocClassifications, getDocTypes, dateSorter } from "../common";

const mapBlueprintContent = (departments, _) => {
  const blueprint_content = { ..._, more: 'Info', key: _.id };
  blueprint_content.updated_at = getDateInFormat(blueprint_content.updated_at)
  blueprint_content.created_at = getDateInFormat(blueprint_content.created_at)
  getUserDetails(blueprint_content, 'creator');
  getUserDetails(blueprint_content, 'updater');
  if (blueprint_content.department_name) {
    const dept = departments.find((_) => _.id === blueprint_content.department_name);
    if (dept) blueprint_content.department_name = dept;
  }
  return blueprint_content
}
function* createContinuerBlueprint({ payload }) {
  yield requestHandler(function* () {
    const body = { continuer_blueprint: payload.continuer_blueprint }
    yield axios.post('/continuer_blueprints/create_con_blueprint', body);
    yield setAlertAndLoading(null, { message: 'Continuer Blueprint Created.' })
    // Once the blueprint is created, fetch the updated list
    yield put(ContinuerActions.fetchContinuityBlueprints({ status: 'draft' }))
  }, "Failed to Create Continuer Blueprint");
}
function* fetchContinuityBlueprints({ payload }) {
  yield requestHandler(function* () {
    let { status } = payload;
    let path = 'draft_con_bp_list';
    switch (status) {
      case 'active': path = 'content_con_bp_list'; break;
      case 'draft': path = 'draft_con_bp_list'; break;
      case 'archive': path = 'archive_con_bp_list'; break;
    }
    let res = yield axios.get('/continuer_blueprints/' + path);
    const departments = yield select(getDepartments);
    res = res.blueprint_list ? res.blueprint_list : res;
    res = Array.isArray(res) ? res : [];
    res.sort(dateSorter.bind(null, 'updated_at'))
    res = res.map((_, i) => {
      const blueprint = mapBlueprintContent(departments, _, i);
      blueprint.link = '/C/' + (blueprint.guid);
      return blueprint;
    });
    yield put(ContinuerActions.setBlueprints({ [status]: res }))
  }, "Failed to Fetch Continuer Blueprints List");
}

function* fetchContinuityBlueprint({ payload }) {
  yield requestHandler(function* () {
    let { id } = payload;
    let res;
    if ((typeof id) === 'string') {
      let conId
      conId = yield axios.post('/continuer_blueprints/get_blueprint_id', { guid: id })
      id = conId.id
    }
    res = yield axios.get('/continuer_blueprints/show_con_blueprint?blueprint_id=' + id);
    const departments = yield select(getDepartments)
    if (res) {
      const blueprint_content = mapBlueprintContent(departments, res)
      yield put(ContinuerActions.setShowBlueprint({ blueprint: blueprint_content }))
    }
  }, "Failed to fetch Continuity Blueprint");
}
function* updateContinuerBlueprint(body) {
  return axios.put('/continuer_blueprints/update_con_blueprint', body)
}
function* updateContinuerBlueprintHistory({ payload }) {
  yield requestHandler(function* () {
    let body = { blueprint_id: payload.id, continuer_blueprint: payload.blueprint }
    yield updateContinuerBlueprint(body);
    if (payload.updateHistory) {
      body = { blueprint_id: payload.id, ...payload.updateHistory }
      yield axios.post('/continuer_blueprints/add_con_blueprint_history', body)
    }
    yield put(ContinuerActions.fetchContinuityBlueprint({ id: payload.id }))
    yield setAlertAndLoading(null, { message: 'Blueprint has been saved.' })
  }, "Failed to fetch Continuer Blueprint");
}
function* fetchBlueprintAudit({ payload }) {
  yield requestHandler(function* () {
    const id = payload.id
    const res = yield axios.get('/continuer_blueprints/show_con_blueprint?blueprint_id=' + id);
    let history = (res.con_blueprint_histories || [])
    history.sort(dateSorter.bind(null, 'created_at'))
    history = history.map((audit, i) => {
      const _a = { ...audit, action: 'Update', key: audit.id }
      getUserDetails(_a, 'creator')
      _a.created_at_base = _a.created_at;
      _a.created_at = getDateInFormat(_a.created_at)
      _a.responsible = `${_a.username ? `${_a.username}, ` : ''}${_a.designation ? `${_a.designation}, ` : ''}${_a.department_name || ''}`;
      _a.summary = `${_a.summary}.`
      return { ..._a };
    });
    //audits
    let audits = (res.audits || [])
    audits = audits.filter((_) => {
      return Boolean(_.audited_changes && Array.isArray(_.audited_changes.status))
    })
    audits = audits.map((audit, i) => {
      const _a = { ...audit }
      _a.designation_function = `${_a.designation ? `${_a.designation}, ` : ''}${_a.department_name || ''}`;
      _a.created_at_base = _a.created_at;
      _a.created_at = getDateInFormat(_a.created_at)
      _a.action = _a.audited_changes.status[1];
      _a.version = _a.version_no;
      _a.creator_designation = _a.designation_function;
      _a.creator_name = audit.username;
      _a.summary = `Status changed from <span class="caps">${audit.audited_changes.status[0]}</span> to <span class="caps">${audit.audited_changes.status[1]}</span>.`
      return _a;
    });
    audits.sort(dateSorter.bind(null, 'created_at'))
    if (history.length > 0) {
      history[history.length - 1].action = 'Draft';
    }
    let updatedHistory = [...history, ...audits]
    updatedHistory.sort(dateSorter.bind(null, 'created_at_base'));
    yield put(ContinuerActions.setBlueprintChangeHistory(updatedHistory))
    yield put(ContinuerActions.setBlueprintAuditHistory(audits))
  }, "Failed to fetch History");
}
function* fetchBlueprintReviewerList({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.get('/continuer_blueprints/reviewer_list');
    const list = (res.reviewer_list || []).map((_) => {
      let reviewerName = _[0]
      if (_[2]) {
        reviewerName = reviewerName + ", " + _[2]
      }
      if (_[3]) {
        reviewerName = reviewerName + ", " + _[3]
      }
      return { id: _[1], label: reviewerName }
    })
    yield put(ContinuerActions.setBlueprintReviwerList(list))
  }, "Failed to fetch reviewer list");
}
function* sendBlueprintForReview({ payload }) {
  yield requestHandler(function* () {
    let body = { blueprint_id: payload.id, reviewer_id: payload.email }
    yield axios.post('/continuer_blueprints/send_blueprint_for_review', body);
    yield setAlertAndLoading(null, { message: 'Blueprint has been sent for Approval' })
  }, "Failed to send for reviewer");
}
function* archiveBlueprint({ payload }) {
  yield requestHandler(function* () {
    const { id } = payload;
    let body = { blueprint_id: id, continuer_blueprint: { status: 'archived' } }
    try {
      yield put(ContinuerActions.setDeleteInProgress(true));
      yield put(ContinuerActions.removeBlueprint(payload));
      yield updateContinuerBlueprint(body)
      yield fetchContinuityBlueprints({ status: 'archive' })
      yield setAlertAndLoading(null, { message: 'Blueprint has been Archived' });
      yield put(ContinuerActions.setDeleteInProgress(false));
    } catch (error) {
      console.error("Failed to archive blueprint:", error);
      yield put(ContinuerActions.setDeleteInProgress(false));
    }
  }, "Failed to archive blueprint");
}

function* unarchiveBlueprint({ payload }) {
  yield requestHandler(function* () {
    const { id, status } = payload
    try {
      yield put(ContinuerActions.setDeleteInProgress(true));
      let body = { blueprint_id: id, blueprint: { status: status } }
      yield axios.put('/continuer_blueprints/change_con_blueprint_status', body)
      yield fetchContinuityBlueprints({ status: 'draft' })
      yield setAlertAndLoading(null, { message: 'Blueprint has been Unarchived' });
      yield put(ContinuerActions.setDeleteInProgress(false));
    } catch (e) {
      console.error("Failed to unarchive blueprint:", e);
      yield setAlertAndLoading(null, {success: false, message: e.data.errors || 'Failed to unarchive blueprint' });
      yield put(ContinuerActions.setDeleteInProgress(false));
    }
  }, "Failed to unarchive blueprint");
}
function* deleteBlueprint({ payload }) {
  yield requestHandler(function* () {
    const { id } = payload;
    let body = { blueprint_id: id }
    try {
      yield put(ContinuerActions.removeBlueprint(payload))
      yield axios.post('/continuer_blueprints/delete_con_blueprint', body);
      yield setAlertAndLoading(null, { message: 'Blueprint has been Deleted' })
      yield put(ContinuerActions.setDeleteInProgress(false))
    } catch (error) {
      console.log(error);
      yield put(ContinuerActions.fetchContinuityBlueprints({ status: 'active' }))
      yield put(ContinuerActions.setDeleteInProgress(false))
    }
  }, "Failed to delete document");
}
const changeBlueprintStatus = function* ({ payload }) {
  yield requestHandler(function* () {
    const { id, status, message } = payload
    let body = { blueprint_id: id, blueprint: { status: status } }
    yield axios.put('/continuer_blueprints/change_con_blueprint_status', body)
    yield fetchContinuityBlueprints({ status: 'draft' })
    yield fetchContinuityBlueprints({ status: 'active' })
    yield fetchContinuityBlueprints({ status: 'archive' })
    yield put(ContinuerActions.fetchContinuityBlueprint({ id: id }))
    yield setAlertAndLoading(null, { message: message || 'Blueprint status has been updated' })
  }, "Failed to update blueprint status");
}
SagaRegistry.register(function* userSaga() {
  yield takeEvery("Continuer/createContinuerBlueprint", createContinuerBlueprint);
  yield takeEvery("Continuer/fetchContinuityBlueprints", fetchContinuityBlueprints);
  yield takeEvery("Continuer/fetchContinuityBlueprint", fetchContinuityBlueprint);
  yield takeEvery("doc/updateContinuerBlueprint", updateContinuerBlueprint);
  yield takeEvery("Continuer/updateContinuerBlueprintHistory", updateContinuerBlueprintHistory);
  yield takeEvery("Continuer/sendBlueprintForReview", sendBlueprintForReview);
  yield takeEvery("Continuer/fetchBlueprintReviewerList", fetchBlueprintReviewerList);
  yield takeEvery("Continuer/archiveBlueprint", archiveBlueprint);
  yield takeEvery("Continuer/unarchiveBlueprint", unarchiveBlueprint);
  yield takeEvery("Continuer/deleteBlueprint", deleteBlueprint);
  yield takeEvery("Continuer/changeBlueprintStatus", changeBlueprintStatus);
  yield takeEvery("Continuer/fetchBlueprintAudit", fetchBlueprintAudit);
})
