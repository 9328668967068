import React from 'react';
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Tooltip } from 'antd';
import { ControllerLogo, DocumentorIcon, ControllerIcon, ProfilePic, CompanyLogo, AuditorIcon, ArcIcon, ConsoleIcon, EducatorIcon, InvestigatorIcon, ContinuerIcon, CentralIcon } from "../Assets";
import "./style.scss";

const DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;

const Modules = [
  { moduleId: 'auditor', Component: AuditorIcon, label: 'Auditor' },
  { moduleId: 'arc', Component: ArcIcon, label: 'Arc' },
  { moduleId: 'console', Component: ConsoleIcon, label: 'Console' },
  { moduleId: 'controller', Component: ControllerIcon, label: 'Controller', isAdmin: true },
  { moduleId: 'documenter', Component: DocumentorIcon, label: 'Documenter' },
  { moduleId: 'educator', Component: EducatorIcon, label: 'Educator' },
  { moduleId: 'investigator', Component: InvestigatorIcon, label: 'Investigator' },
  { moduleId: 'central', Component: CentralIcon, label: 'Central' },
  { moduleId: 'continuer', Component: ContinuerIcon, label: 'Continuer' },
]
const ControllerModules = [
  { moduleId: 'documenter', Component: DocumentorIcon, tooltip: 'Documenter', to: '/D' },
  { moduleId: 'educator', Component: EducatorIcon, tooltip: 'Educator', to: '/E' },
  { moduleId: 'continuer', Component: ContinuerIcon, tooltip: 'Continuer', to: '/C' },
  // { moduleId: 'central', Component: CentralIcon, tooltip: 'Central', to: '/G' }
]
const getModuleLink = (moduleId, user) => {
  const sessionToken = user.session_token;
  return `https://${moduleId}.${DOMAIN}/login?sessionToken=${sessionToken}`;
}
export const NavBar = (props) => {
  const { user, isAdmin, isArchitect, currentModule } = props
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    let logoutPath = getModuleLink('console', user);
    logoutPath += '&redirect=logout'
    window.location.assign(logoutPath)
  }
  const getSubscribedModules = () => {
    let modules = Array.from(Modules);
    modules = modules.filter((mod) => {
      return mod.isAdmin ? (isAdmin || isArchitect) : mod.moduleId !== currentModule;
    })
    modules.sort((modA, modB) => {
      let moduleIdA = String(modA.moduleId).toLowerCase(),
        moduleIdB = String(modB.moduleId).toLowerCase();
      return moduleIdA.localeCompare(moduleIdB)
    })
    return modules
  }
  return (
    <nav className='row w-100 nav-bar h-btn'>
      <ControllerLogo className='logo' onClick={() => navigate('/')} />
      <div className='row'>
        <button className='btn row btn-profile no-border' onClick={handleClick}>
          <ProfilePic className='profile-img' />
          {/* <span className='f9 reg'>{user.firstname} {user.lastname}</span> */}
          <span className='f12'>
            <i className='c00085 icon-caret-down icon' />
          </span>
        </button>
        <CompanyLogo user={user} />
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <div className='col documenter-menus'>
          <div className='btn w-100 row btn-profile v-start no-border'>
            <ProfilePic className='profile-img' />
            <div className='col f-rest usename'>
              <div className='col w-100'>
                <span className='f7 reg'>{user.firstname} {user.lastname}</span>
                <span className='f10 reg c1155CC'>{user.email}</span>
              </div>
              <div className='row h-btn profile-signout w-100'>
                <Link to={getModuleLink('console', user) + '&redirect=profile'} target='_blank' className='f9 profile' >Profile</Link>
                <Link className='f9 logout' onClick={handleLogout}>Sign Out</Link>
              </div>
            </div>
          </div>
          {
            getSubscribedModules().map(({ label, Component, moduleId }) => {
              return (
                <a className='row v-ctr f9 menu-entry link c00085' rel="noreferrer" key={moduleId} href={getModuleLink(moduleId, user)}>
                  <Component className="mod-logo" />
                  <span>{label}</span>
                </a>
              )
            })
          }
        </div>
      </Menu>
    </nav>
  )
}
export const ModuleNavBar = (props) => {
  const { pathname } = props
  return (
    <div className='col h-100 nav-module-switch'>
      {
        ControllerModules.map(({ Component, moduleId, tooltip, to }) => {
          let index = pathname.indexOf(to);
          return (
            <Tooltip key={moduleId} title={tooltip} placement="right">
              <Link className={`mod ${index === 0 ? 'active' : ''}`} to={to}>
                <Component className='icon' />
              </Link>
            </Tooltip>

          )
        })
      }
    </div>
  )
}
export const ModuleInnerNavBar = (props) => {
  const { Items, pathname } = props;
  return (
    <nav className='col doc-nav h-100' >
      {
        Items.map((item, index) => {
          const active = pathname.includes(item.link)
          const color = active ? 'cFFF' : 'c1E686D'
          return (
            <Link key={index}
              to={item.link}
              className={`row  v-ctr item ${active ? 'active' : ''}`}>
              <i className={`icon ${item.icon} ${color}`} />
              <span className={`f9 ${color}`}>{item.tooltip}</span>
            </Link>
          )
        })
      }
    </nav>
  )
}
export const ModuleInnerHorizontalNavBar = (props) => {
  const { Items, pathname } = props;
  return (
    <nav className='row horizontal-nav w-100' >
      {
        Items.map((item, index) => {
          const active = pathname.includes(item.link)
          const color = active ? 'cFFF' : 'c1E686D'
          return (
            <Link key={index}
              to={item.link}
              className={`row  v-ctr item ${active ? 'active' : ''}`}>
              <i className={`icon ${item.icon} ${color}`} />
              <span className={`f9 ${color}`}>{item.tooltip}</span>
            </Link>
          )
        })
      }
    </nav>
  )
}

export const useNavigateBack = () => {
  const navigate = useNavigate();
  const navigateBack = (search) => {
    if (window.history && window.history.state) {
      navigate(-1)
    } else {
      navigate('/')
    }
  }
  return navigateBack
}