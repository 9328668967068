import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

const StatusList = [
  { id: 'active', label: 'Active' },
  { id: 'inactive', label: 'Inactive' },
  { id: 'outline', label: 'Outline' },
  { id: 'draft', label: 'Outline' },
  { id: 'approve', label: 'Approved' },
  { id: 'archive', label: 'Archived' }
]
const VariableSources = [
  { id: 'console', label: 'Console' },
  { id: 'content', label: 'Content' },
  { id: 'presentation', label: 'Presentation' },
]

const initialState = {
  variables: null,
  activeContents: null,
  draftContents: null,
  presentations: null,
  archiveContents: null,
  archivePresentations: null,
  changeHistory: null,
  StatusList,
  VariableSources,
  newDocumentId: '',
  showBlueprint: null,
  deleteInProgress: false,
  auditHistory: null
}
const findDocIndex = (list, id) => {
  return list.findIndex((_) => _.id === id)
}

const docSlice = createSlice({
  name: "doc",
  initialState: initialState,
  reducers: {
    addVariable: () => { },
    fetchAllVariables: () => { },
    createContentBlueprint: () => { },
    createPresentationBlueprint: () => { },
    fetchContentBlueprints: () => { },
    fetchPresentationBlueprints: () => { },
    fetchContentAudit: () => { },
    fetchBlueprint: () => { },
    updateBlueprint: () => { },
    updatePresentation: () => { },
    sendDocForReview: () => { },
    archiveDocument: () => { },
    unarchiveDocument: () => { },
    deleteDocument: () => { },
    updateStatus: () => { },
    setAllVariables: (state, action) => {
      state.variables = action.payload
    },
    setBlueprintChangeHistory: (state, action) => {
      state.changeHistory = action.payload
    },
    setBlueprintAuditHistory: (state, action) => {
      state.auditHistory = action.payload
    },
    setContentBlueprints: (state, action) => {
      state.activeContents = action.payload.active;
      state.draftContents = action.payload.draft;
      state.archiveContents = action.payload.archive;
    },
    setShowBlueprint: (state, action) => {
      const { blueprint } = action.payload;
      state.showBlueprint = blueprint
    },
    fetchNewDocId: (state) => {
      state.newDocumentId = ''
    },
    setNewDocId: (state, action) => {
      state.newDocumentId = action.payload
    },
    setPresentationBlueprints: (state, action) => {
      state.presentations = action.payload.active;
      state.archivePresentations = action.payload.archive;
    },
    removeBlueprint: (state, action) => {
      const { id, isContent } = action.payload;
      if (isContent) {
        let index, activeContents = [...state.activeContents],
          draftContents = [...state.draftContents],
          archiveContents = [...state.archiveContents];
        index = findDocIndex(activeContents, id);
        if (index > -1) {
          activeContents.splice(index, 1);
          state.activeContents = [...activeContents];
          return;
        }
        index = findDocIndex(draftContents, id);
        if (index > -1) {
          draftContents.splice(index, 1);
          state.draftContents = [...draftContents];
          return;
        }
        index = findDocIndex(archiveContents, id);
        if (index > -1) {
          archiveContents.splice(index, 1);
          state.archiveContents = [...archiveContents];
          return;
        }
      } else {
        let index, presentations = [...state.presentations],
          archivePresentations = [...state.archivePresentations];
        index = findDocIndex(presentations, id);
        if (index > -1) {
          presentations.splice(index, 1);
          state.presentations = [...presentations];
          return;
        }
        index = findDocIndex(archivePresentations, id);
        if (index > -1) {
          archivePresentations.splice(index, 1);
          state.archivePresentations = [...archivePresentations];
          return;
        }
      }
    },
    setDeleteInProgress: (state, action) => {
      state.deleteInProgress = action.payload
    }
  }
})
export const docActions = docSlice.actions;

ReducerRegistry.register("doc", docSlice.reducer)