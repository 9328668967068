import React from 'react';
import { NeumetricLogo } from "../Assets";
import { Link } from 'react-router-dom';
const DocumentStatus = [
  'draft', 'reviewed', 'approved', 'published'
]

export const Header = (props) => {
  const { pageNo, totalPages, document, user, isSchemaOnly, classifications } = props;
  return (
    <div className='col w-100 page-header'>
      <div className='row v-start logo-sec'>
        <div className='f10 line-20 col f-rest'>
          <div className='row h-btn w-100'>
            <div className='f19 row fist-sec'>
              <span>Information Security Management System [ISMS]</span>
            </div>
            <div className='row f-rest'>
              <span className='key gap'>Organisation:</span>
              <span className='c0133CC bold'>{isSchemaOnly ? "[[OrgShortname]]" : user.ent_org.brandname}</span>
            </div>
          </div>
          <div className='row w-100'>
            <span className='key'>Document:</span>
            <span className='c00085 bold'>{isSchemaOnly ? '[[DocumentTitle]]' : document.name}</span>
          </div>
          <div className='row w-100'>
            <span className='key'>Filename:</span>
            {
              isSchemaOnly ?
                <span className='c0133CC bold'>[[LinkedFilename]]</span>
                :
                <Link to={document.doc_link} className='c0133CC bold' >{document.doc_linkname}</Link>
            }
          </div>
          <div className='row h-btn w-100'>
            <div className=' row fist-sec'>
              <span className='key'>Version:</span>
              <span className='c00085 bold'>{isSchemaOnly ? '###' : document.version}</span>
            </div>
            <div className='row f-rest'>
              <span className='key gap'>Department:</span>
              <span className='c0133CC bold'>{isSchemaOnly ? '[[Department]]' : document.department_name.label}</span>
            </div>
          </div>
          <div className='row w-100'>
            <span className='key'>Status:</span>
            {
              isSchemaOnly ?
                <div className='row'>
                  {
                    DocumentStatus.map((status) => {
                      return <span className={`c00085 bold caps status ${status}`}>{status}</span>
                    })
                  }
                </div>
                :
                <span className={`c00085 bold caps status ${document.status.id}`}>{document.status.label}</span>
            }
          </div>
        </div>
        {
          isSchemaOnly ?
            <NeumetricLogo className='logo' />
            :
            <img className='logo' src={`http:${user.ent_org.logo_url}`} />
        }

        <div className='f10 line-20 row page-sec'>
          <span className='key small '>Page:</span>
          <span className='c00085 bold'> - <span>{pageNo}</span> of {totalPages} - </span>
        </div>
      </div>
      <div className='row v-ctr h-ctr'>
        <span className='f10 key gap'>Classification: </span>
        {

          [...(isSchemaOnly ? classifications : [document.classification])]
            .map(({ id, label }) => {
              return <span className={`f8 bold caps classif ${id}`}>{label}</span>
            })
        }
      </div>
    </div>
  )
}
export const Footer = (props) => {
  const { pageNo, totalPages, user, document, isSchemaOnly } = props;
  const OrgShortname = isSchemaOnly ? '[[OrgShortname]]' : user.ent_org.brandname;
  const OrgEmailDomain = isSchemaOnly ? '[[orgdomain]]' : user.ent_org.email_domain;
  return (
    <div className='col footer'>
      <div className='f10 w-100 row h-ctr'>
        <span className='key small'>Page:</span>
        <span className='c00085 '> - <span className='bold'>{pageNo}</span> of {totalPages} - </span>
      </div>
      <p className='f10 txt-justify c777 txt'>
        <span className='bold'>Copyright</span> © {OrgShortname}. Controlled Master of this document exists in the ISMS within {OrgShortname}'s corporate network. Printed copies, standalone versions in PDF or other file formats & copies outside {OrgShortname}'s corporate network are considered “<span className='cc0000 bold'>Uncontrolled</span>“ & may not be valid. Uncontrolled copies of this Document should not be distributed freely. Contact {OrgShortname}'s InfoSec Dept at <span className='bold c0133CC'>infosec@{OrgEmailDomain}</span> to obtain the latest version of this Document.
      </p>
      <div className='f10 w-100 row h-end version'>
        <span className='pres c999'>Presentation: {isSchemaOnly ? '###' : (document.presentation && document.presentation.version) || '###'}</span>
        <span className='content c999'>Content: {isSchemaOnly ? '###' : document.version} </span>
      </div>
    </div>
  )
}
export const HistoryTable = (props) => {
  const { rows, title, Columns, isSchemaOnly } = props;
  return (
    <div className={`col doc-history`}>
      <table className={`history-tbl ${isSchemaOnly ? 'schema' : ''}`}>
        <thead>
          <tr>
            {
              Columns.map((col) => {
                return (
                  <th style={{ width: col.width }} key={col.dataIndex} className={`cell cFFF bold ${isSchemaOnly ? 'bg882222' : 'bg238787'} ${col.className}`}>{col.title}</th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            Array.isArray(rows) && rows.length > 0 &&
            rows.map((row, index) => {
              return (
                <tr key={index}>
                  {
                    Columns.map((col) => {
                      return (
                        <td className={`cell ${col.className}`} key={col.dataIndex} dangerouslySetInnerHTML={{ __html: row[col.dataIndex] }}></td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}