/* @flow */

import { all } from 'redux-saga/effects';

/**
 * A registry for Redux middleware, allowing features to register their
 * middleware without needing to create additional inter-feature dependencies.
 */
export default class SagaRegistryHelper {
  _sagas;

  /**
   * Creates a SagaRegistry instance.
   */
  constructor() {
    /**
     * The set of registered middleware.
     *
     * @private
     * @type {GeneratorFunction[]}
     */
    this._sagas = [];
  }

  /**
   * get all registered sagas into a store enhancer.
   * (@link http://redux.js.org/docs/api/applyMiddleware.html).
   *
   * @returns {GeneratorFunction}
   */
  getAllSagas() {
    // $FlowExpectedError
    let sagas = [];
    this._sagas.forEach(saga => {
      sagas.push(saga())
    });
    return function* rootSaga() {
      yield all(sagas)
    };
  }

  /**
   * Adds a Saga to the registry.
   *
   * The method is to be invoked only before {@link #applyMiddleware()}.
   *
   * @param {GeneratorFunction} saga - A Generator function.
   * @returns {void}
   */
  register(saga) {
    this._sagas.push(saga);
  }
}