import React from 'react';
import { Button, LinkButton } from "../Button";
import { FormDialog } from "../Dialog";
import { Radio, Space } from 'antd';
import "./style.scss";

const Options = [
  { id: 'option_1', label: 'Option 1' },
  { id: 'option_2', label: 'Option 2' },
  { id: 'option_3', label: 'Option 3' },
  { id: 'option_4', label: 'Option 4' }
]

const Question = (props) => {
  const { question, readOnly } = props
  const handleOptionchange = (e) => {

  }
  return (
    <div className='col w-100 assess-question'>
      <div className='c00085 f9 reg'>Question {question.q_id < 9 ? `0` : ''}{question.q_id}</div>
      <h6 className='f7 c000 stem reg'>{question.stem}</h6>
      <Radio.Group onChange={handleOptionchange} value={question.answer} name='answer' >
        <Space direction="vertical" className='col w-100'>
          {
            Options.map((opt) => {
              return (
                <Radio className='w-100 option' value={opt.id} key={opt.id} >{question[opt.id]}</Radio>
              )
            })
          }
        </Space>
      </Radio.Group>
    </div>
  )
}

export const EvaluationAssementor = (props) => {
  const { evaluation, readOnly } = props;
  return (
    <FormDialog
      className="eval-assess"
      TitleComponent={() => {
        return (
          <div className='col w-100 title-sec'>
            <div className='row w-100 h-btn title-row'>
              <h6 className='exo2 f4 med title c00085'>{evaluation.title}</h6>
              {
                !Boolean(readOnly) &&
                <Button label="Start Assessment" />
              }
            </div>
            <h6 className='f8 med c00085'>Assessment</h6>
          </div>
        )
      }}
      onClose={props.onClose}>
      <div className='col w-100 h-100 o-hide bgWhite'>
        <div className='col f-rest oy-auto'>
          {
            evaluation.questions.map((question) => {
              return <Question question={question} key={question.id} readOnly={readOnly} />
            })
          }
        </div>
      </div>
    </FormDialog>
  )
}