import React from 'react';
import { Form, TextInput } from "../Input";
import { Button } from "../Button";
import "./style.scss";

const Fields = [
  { label: 'Question', attribute: 'stem', placeholder: "Question", required: true, className: 'w-100', direction: 'col', inputContainerClassName: 'bgedf1f7' },
  { label: 'Option 1', attribute: 'option_1', placeholder: "Option 1", required: true, className: 'w-49', direction: 'col', inputContainerClassName: 'bgedf1f7' },
  { label: 'Option 2', attribute: 'option_2', placeholder: "Option 2", required: true, className: 'w-49', direction: 'col', inputContainerClassName: 'bgedf1f7' },
  { label: 'Option 3', attribute: 'option_3', placeholder: "Option 3", required: true, className: 'w-49', direction: 'col', inputContainerClassName: 'bgedf1f7' },
  { label: 'Option 4', attribute: 'option_4', placeholder: "Option 4", required: true, className: 'w-49', direction: 'col', inputContainerClassName: 'bgedf1f7' },
  { label: 'Answer', attribute: 'answer', type: 'select', placeholder: "", required: true, className: 'w-49', direction: 'col', inputContainerClassName: 'bgedf1f7' },
]

export const QuestionDefaultValues = {
  positive_mark: 1, negative_mark: 0, stem: '', option_1: '',
  option_2: '', option_3: '', option_4: '', answer: '', q_id: 0
}

export const validateQuestion = (question) => {
  let valid = true;
  for (let i = 0; i < Fields.length; i++) {
    const { attribute } = Fields[i];
    if (!question[attribute]) {
      valid = false;
      break;
    }
  }
  return valid
}
export const findDuplicates = (questions) => {
  let map = {}, result = null;
  if (Array.isArray(questions) && questions.length > 0) {
    result = [];
    questions.map((q, index) => {
      let options = {}, optionDup = {};
      let key = Fields.map(({ attribute }) => {
        let val = String(q[attribute]).trim().toLowerCase();
        if (val && val.length > 0 && attribute.indexOf("option_") === 0) {
          if (!options[val]) {
            options[val] = true
          } else {
            optionDup[attribute] = `Duplicate Option`;
          }
        }
        return val;
      });
      key = key.join("_");
      if (Boolean(map[key])) {
        result[index] = { stem: `Duplicate with Question ${map[key]}` }
      } else if (Object.keys(optionDup).length > 0) {
        result[index] = optionDup
      } else {
        result[index] = null;
        map[key] = index + 1
      }
    })
  }
  return result;
}

export const AssessmentOptions = [
  { id: 'option_1', label: 'Option 1' },
  { id: 'option_2', label: 'Option 2' },
  { id: 'option_3', label: 'Option 3' },
  { id: 'option_4', label: 'Option 4' }
]
export const EvaluationQuestion = (props) => {
  const { question, index, error, handleDeleteQuestion } = props;
  const slNo = index + 1;
  const getOptions = () => {
    return AssessmentOptions
  }
  const handleValueChange = (e) => {
    const { name, value } = e.target;
    let target = { value, attribute: name, index, name: 'questions' }
    props.onChange && props.onChange({ target })
  }
  return (
    <div className='col evaluation-question-card'>
      <div className='row header'>
        <h6 className='med 00085 f8'>Question {slNo > 9 ? slNo : `0${slNo}`}</h6>
        <TextInput name='positive_mark' inputContainerClassName='bgedf1f7' error={error ? error.positive_mark : null} label='Marks for Answer' value={question.positive_mark} onChange={handleValueChange} />
        <TextInput name='negative_mark' inputContainerClassName='bgedf1f7' error={error ? error.negative_mark : null} label='Negative Marks' value={question.negative_mark} onChange={handleValueChange} />
        <Button label='Delete Question' className='bgCFE3333 ml6' onClick={() => { handleDeleteQuestion(index) }} />
      </div>
      <div className='row w-100 fwarp h-btn question-form'>
        {
          <Form
            Fields={Fields}
            error={error}
            fieldClass='eval-field'
            getOptions={getOptions}
            onChange={handleValueChange}
            formData={{ ...question }} />
        }
      </div>
    </div>
  )
}

