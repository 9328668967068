import React, { useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { Tabs } from 'antd';
import { FormDialog, AppErrorBoundry, blueprintTable, NMCKEditor, OvalLoading, DocAuditTableCols, DocChangeTableCols, DocumentTable, ROW_EXPAND_POLICY } from "../../../components";
import { getContinuerChangeHistory, getCurrentUser, getDateInFormat, getContinuerAuditHistory } from "../../../store";

const Fields = [
  { label: 'Scenario', attribute: 'title', className: 'field', valClassName: 'bold caps' },
  { label: 'Department', attribute: 'department', className: 'field', valClassName: 'bold caps' },
  { label: 'Creator', attribute: 'creator_listname', className: 'field', valClassName: 'bold caps' },
  { label: 'Created', attribute: 'created_at', className: 'field', valClassName: 'bold caps' },
]
const TableCols = [
  { title: 'Action', dataIndex: 'action', key: 'action', width: '70px', className: 'exo2 f10 txt-left caps', cellClassName: 'f10' },
  { title: 'Version', dataIndex: 'version', key: 'version', width: '70px', className: 'exo2 f10 txt-left', cellClassName: 'f10' },
  { title: 'Name', dataIndex: 'creator_name', key: 'creator_name', width: '37%', className: 'exo2 f10 txt-left', cellClassName: 'f10' },
  { title: 'Designation, Function', dataIndex: 'creator_designation', key: 'creator_designation', width: '63%', className: 'exo2 f10 txt-left no-wrap', cellClassName: 'f10' },
  { title: 'Date', dataIndex: 'created_at', key: 'created_at', width: '140px', className: 'exo2 f10 text-center', cellClassName: 'f10' }
]
export const ConHistoryDialog = (props) => {
  const [state, setState] = React.useState({ expandedRows: [] })
  const { blueprint } = props;
  const history = useSelector(getContinuerChangeHistory)
  const getValue = (field) => {
    const value = blueprint[field.attribute]
    return value && (typeof value === 'object') ? value.label : value
  }
  const DescriptionRow = (record) => {
    return (
      <div className='col w-100'>
        <div className='col header exo2 f10 bold c00085'>Summary</div>
        <div className='w-100 bgWhite summary f10' dangerouslySetInnerHTML={{ __html: record.summary }}></div>
      </div>
    )
  }
  return (
    <FormDialog
      className='col-reverse history-dialog dialog-width'
      leftBtn={{ label: "Close ", color: '#0133CC', onClick: props.onClose, variant: 'lite' }}>
      <AppErrorBoundry>
        <div className='col content o-hide'>
          <h6 className='f3 med title c238787 exo2'>Change History</h6>
          {/* <div className='row w-100 fwarp'>
            {
              Fields.map((_) => {
                let value = getValue(_)
                return (
                  <div className={`row gap v-start exo2 ${_.className}`}>
                    <label className='f9 label-con exo2'>{_.label}: </label>
                    <div className={`f9 exo2 ${_.valClassName}`} dangerouslySetInnerHTML={{ __html: value }}></div>
                  </div>
                )
              })
            }
          </div> */}
          <div className='col history'>
            {
              Array.isArray(history) ?
                <DocumentTable
                  noSelection
                  rows={history || []}
                  Columns={TableCols}
                  scrollOffset={150}
                  expandPolicy={ROW_EXPAND_POLICY.ALWAYS}
                  onRowExpandRender={DescriptionRow}
                />
                :
                <OvalLoading isSmall />
            }
          </div>
        </div>
      </AppErrorBoundry>
    </FormDialog>
  )
}
const UpdateDiscordConfirmDialog = (props) => {
  return (
    <FormDialog
      titleClass='cFE3333 med exo2 f3'
      title='Discard this Scenario Blueprint?'
      className='search-var update-history-confirm'
      actions={[
        { label: "Discard", variant: 'lite', color: '#FE3333', onClick: props.onDiscord },
        { label: "Cancel", onClick: props.onClose },
      ]}>
      <div className='col content'>
        <p className='f8 line-22'>This Scenario Blueprint will NOT be saved.<br />Confirm this action.<br />To stop this action press Cancel.</p>
      </div>
    </FormDialog>
  )
}
export const UpdateHistoryDialog = (props) => {
  const { blueprint } = props;
  const ref = useRef(null)
  const [state, setState] = useState({ disableSave: true, showConfirm: false })
  const { _name, designation, department_name } = useSelector(getCurrentUser);
  const handleInstanceReady = (e) => {
    ref.current = e.editor;
    if (ref.current) {
      ref.current.setData('');
      setState((_) => ({ ..._, disableSave: true }))
    }
  }
  const handleSaveWithUpdate = () => {
    const editor = ref.current;
    if (editor) {
      const text = editor.document.getBody().getText();
      props.onSave && props.onSave(editor.getData(), text);
    }
  }
  const handleCKChange = () => {
    const editor = ref.current;
    if (editor) {
      const text = editor.document.getBody().getText().trim();
      let disable = text === '' || text === '\n' || (text.length === 1 && text.charCodeAt(0) === 173);
      setState((_) => ({ ..._, disableSave: disable }))
    }
  }
  const handkleDiscord = (value) => {
    setState((_) => ({ ..._, showConfirm: value }))
  }
  return (
    <React.Fragment>
      <FormDialog
        titleClass='c238787 med exo2 f3'
        title='Update Blueprint History'
        className='search-var update-history'
        actions={[
          { label: "Cancel", variant: 'lite', color: '#0133CC', onClick: props.onClose },
          { label: "Discard", variant: 'lite', color: '#FE3333', onClick: handkleDiscord.bind(null, true), disabled: state.disableSave },
          {
            label: "Save", onClick: handleSaveWithUpdate,
            disabled: state.disableSave
          }
        ]}>
        <div className='col content'>

          <div className='row w-100 h-btn'>

            <div className='row font-input text-input h-end'>
              <label className='f9 c00085 label'>Version : </label>
              <div className='f9 row f-rest input bold'>{blueprint.version}</div>
            </div>
            <div className='row font-input text-input'>
              <label className='f9 c00085 label'>Date : </label>
              <div className='f9 input row f-rest bold'>{getDateInFormat()}</div>
            </div>
          </div>
          <div className='row w-100 font-input text-input'>
            <label className='f9 c00085 label'>Professional : </label>
            <div className='f9 input row f-rest'><span className='bold'>{_name}</span>, {designation} - {department_name}</div>
          </div>
          <div className='col f-rest font-input text-input'>
            <label className='f9 c00085 label' style={{ marginBottom: '8px' }}>Description : </label>
            <NMCKEditor
              onChange={handleCKChange}
              onInstanceReady={handleInstanceReady} />
          </div>
        </div>
      </FormDialog>
      {
        state.showConfirm &&
        <UpdateDiscordConfirmDialog
          onClose={handkleDiscord.bind(null, false)}
          onDiscord={props.onDiscord}
        />
      }
    </React.Fragment>
  )
}