import { combineReducers } from 'redux';

export default class ReducerRegistryHelper {
  constructor() {
    this._reducers = {};
  }

  /**
   * Adds a reducer to the registry.
   *
   * The method is to be invoked only before {@link #combineReducers()}.
   *
   * @param {string} name - The field in the state object that will be managed
   * by the provided reducer.
   * @param {Reducer} reducer - A Redux reducer.
   * @returns {void}
   */
  register(name, reducer) {
    this._reducers[name] = reducer;
  }

  /**
   * Combines all registered reducers into a single reducing function.
   *
   * @param {Object} [additional={}] - Any additional reducers that need to be
   * included (such as reducers from third-party modules).
   * @returns {Function}
   */
  combineReducers(additional = {}) {
    // $FlowExpectedError
    return combineReducers({
      ...this._reducers,
      ...additional
    });
  }
}
