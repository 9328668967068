import React from 'react';
import { OvalLoading } from "../Screens";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

export const PDFViewer = (props) => {
  const { evidence_url_full, blob } = props
  const [pdfContent, setPDFContent] = React.useState(null)
  React.useEffect(() => {
    return () => {
      if (blob && pdfContent) {
        console.log('revoked 1');
        URL.revokeObjectURL(pdfContent)
      }
    }
  }, [])
  React.useEffect(() => {
    let contentURL
    if (blob) {
      contentURL = URL.createObjectURL(blob);
    } else {
      contentURL = evidence_url_full
    }
    if (contentURL) {
      setPDFContent(contentURL)
    }
    return () => {
      if (blob && pdfContent) {
        console.log('revoked 2');
        URL.revokeObjectURL(pdfContent)
      }
    }
  }, [blob])
  return (
    <React.Fragment>
      {
        Boolean(pdfContent) ?
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
            <Viewer fileUrl={pdfContent} />
          </Worker>
          :
          <OvalLoading isSmall />
      }
    </React.Fragment>
  )
}