import { put, takeEvery } from "redux-saga/effects";
import { SagaRegistry, setAlertAndLoading, axios, requestHandler, getDateInFormat } from "../app";
import { dateSorter } from "../common";
import { eduActions } from "./slice";

function* fetchCirculars({ payload }) {
  yield requestHandler(function* () {
    const { pageNo, search, status } = payload;
    const body = { circular: { ...(search || {}), status } }
    const res = yield axios.post(`/educators/circular_search?page=${pageNo}&per_page=10`, body);
    let circulars = res.circular_list ? res.circular_list : res;
    let totalCount = res.total_count ? res.total_count : circulars.length;
    circulars = Array.isArray(circulars) ? circulars : [];
    circulars.sort(dateSorter.bind(null, 'created_at'));
    circulars = circulars.map((_) => {
      let cir = { ..._ };
      cir.created_at = getDateInFormat(cir.created_at);
      cir.link = `${_.guid || _.id}/edit`;
      cir.action = 'Edit';
      return cir
    })
    yield put(eduActions.setCirculars({ [status]: { rows: circulars, totalCount } }))
  }, "Failed to load Circulars");
}
function* fetchEvaluations({ payload }) {
  yield requestHandler(function* () {
    const { pageNo, search, status } = payload;
    const body = { evaluation: { ...(search || {}), status } }
    const res = yield axios.post(`/educators/evaluation_search?page=${pageNo}&per_page=10`, body);
    let evaluations = res.evaluation_list ? res.evaluation_list : res;
    evaluations = Array.isArray(evaluations) ? evaluations : [];
    let totalCount = res.total_count ? res.total_count : evaluations.length;
    evaluations.sort(dateSorter.bind(null, 'created_at'));
    evaluations = evaluations.map((_) => {
      let ev = { ..._ };
      ev.created_at = getDateInFormat(ev.created_at)
      return ev
    })
    yield put(eduActions.setEvaluations({ [status]: { rows: evaluations, totalCount } }))
  }, "Failed to load evaluations");
}
function* fetchLearnings({ payload }) {
  yield requestHandler(function* () {
    const { pageNo, search, status } = payload;
    const body = { learning: { ...(search || {}), status } }
    const res = yield axios.post(`/educators/learning_search?page=${pageNo}&per_page=10`, body);
    let active = [], inactive = [], archive = [];
    let learnings = res.learning_list ? res.learning_list : res;
    let totalCount = res.total_count ? res.total_count : learnings.length;
    learnings = Array.isArray(learnings) ? learnings : [];
    learnings.sort(dateSorter.bind(null, 'created_at'));
    learnings = learnings.map((_, i) => {
      let ev = { ..._ };
      ev.title = _.name || `Learning 0${i + 1}`
      ev.created_at = getDateInFormat(ev.created_at)
      return ev;
    })
    yield put(eduActions.setLearnings({ [status]: { rows: learnings, totalCount } }))
  }, "Failed to load learnings");
}
function* fetchAssessmentById({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.post('/educators/evaluation_questions', payload);
    yield put(eduActions.setCurrentContent(res))
  }, "Failed to load assessment");
}
function* fetchLearningById({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.get(`/educators/show_learning?learning_id=${payload.id}`);
    if (res && res.learn_type === 'presentation' && Array.isArray(res.attachments) && res.attachments.length > 0) {
      let url = `/educators/s3_download_file?url=${res.attachments[0].evidence_url}`
      res.attachments[0].evidence_url_full = axios.getFullPath(url);
      res.attachments[0].blob = yield fetch(res.attachments[0].evidence_url_full, { method: "GET", headers: axios.getHeaders().headers }).then(_ => _.blob())
      // console.log('res.attachments[0].blob', res.attachments[0].blob);
    }
    yield put(eduActions.setCurrentContent(res));
  }, "Failed to load learnings");
}
function* fetchCircularById({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.get(`/educators/show_circular?circular_id=${payload.id}`);
    yield put(eduActions.setCurrentContent(res))
  }, "Failed to load Circular");
}
function* addNewCircular({ payload }) {
  yield requestHandler(function* () {
    yield axios.post('/educators/create_circular', payload, axios.getFormDataContentType());
    yield put(eduActions.fetchCirculars({ status: 'active', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Circular Created Successfully.' })
  }, "Failed to add new Circular");
}
function* addNewEvaluation({ payload }) {
  yield requestHandler(function* () {
    const { upload, ...body } = payload
    yield axios.post('/educators/create_evaluation', body);
    yield put(eduActions.fetchEvaluations({ status: 'active', pageNo: 1 }));
    let message = 'Assessment Created Successfully.'
    if (upload) {
      message = 'Upload Successfully.;Assessment Created Successfully.'
    }
    yield setAlertAndLoading(null, { message: message })
  }, "Failed to add new assessment");
}
function* addNewLearning({ payload }) {
  yield requestHandler(function* () {
    yield axios.post('/educators/create_learning', payload, axios.getFormDataContentType());
    yield put(eduActions.fetchLearnings({ status: 'active', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Learning Created Successfully.' })
  }, "Failed to add new learning");
}
function* updateCircular({ payload }) {
  yield requestHandler(function* () {
    yield axios.put('/educators/edit_circular', payload, axios.getFormDataContentType());
    yield put(eduActions.fetchCirculars({ status: 'active', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Circular Updated Successfully.' })
  }, "Failed to update Circular");
}
function* updateEvaluation({ payload }) {
  yield requestHandler(function* () {
    yield axios.post('/educators/update_evaluation', payload);
    yield put(eduActions.fetchEvaluations({ status: 'active', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Assessment Updated Successfully.' })
  }, "Failed to save assessment");
}
function* updateLearning({ payload }) {
  yield requestHandler(function* () {
    yield axios.put('/educators/edit_learning', payload, axios.getFormDataContentType());
    yield put(eduActions.fetchLearnings({ status: 'active', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Learning Updated Successfully.' })
  }, "Failed to save learning");
}
function* archiveLearning({ payload }) {
  yield requestHandler(function* () {
    const { id } = payload
    const body = new FormData();
    body.append('learning_id', id);
    body.append('learning[status]', 'archive');
    yield axios.put('/educators/edit_learning', body, axios.getFormDataContentType());
    yield setAlertAndLoading(null, { message: 'Learning Archived Successfully.' })
  }, "Failed to archive learning");
}
function* archiveCircular({ payload }) {
  yield requestHandler(function* () {
    const { id } = payload
    const body = new FormData();
    body.append('circular_id', id);
    body.append('circular[status]', 'archive');
    yield axios.put('/educators/edit_circular', body, axios.getFormDataContentType());
    yield put(eduActions.fetchCirculars({ status: 'active', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Circular Archived Successfully.' })
  }, "Failed to archive Circular");
}
function* archiveEvaluation({ payload }) {
  yield requestHandler(function* () {
    const { id } = payload
    const body = { evaluation: { id, status: 'archive' } }
    yield axios.post('/educators/update_evaluation', body);
    yield put(eduActions.fetchEvaluations({ status: 'active', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Assessment Archived Successfully.' })
  }, "Failed to archive Assessment");
}
function* unArchiveLearning({ payload }) {
  yield requestHandler(function* () {
    const { id } = payload
    const body = new FormData();
    body.append('learning_id', id);
    body.append('learning[status]', 'active');
    yield axios.put('/educators/edit_learning', body, axios.getFormDataContentType());
    yield put(eduActions.fetchLearnings({ status: 'archive', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Learning Unarchived Successfully.' })
  }, "Failed to unarchive learning");
}
function* unArchiveCircular({ payload }) {
  yield requestHandler(function* () {
    const { id } = payload
    const body = new FormData();
    body.append('circular_id', id);
    body.append('circular[status]', 'active');
    yield axios.put('/educators/edit_circular', body, axios.getFormDataContentType());
    yield put(eduActions.fetchCirculars({ status: 'archive', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Circular Unarchived Successfully.' })
  }, "Failed to unarchive learning");
}
function* unArchiveAssessment({ payload }) {
  yield requestHandler(function* () {
    const { id } = payload
    const body = { evaluation: { id, status: 'active' } }
    yield axios.post('/educators/update_evaluation', body);
    yield put(eduActions.fetchEvaluations({ status: 'active', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Assessment Unarchived Successfully.' });
  }, "Failed to unarchive learning");
}
function* deleteLearning({ payload }) {
  yield requestHandler(function* () {
    yield axios.post('/educators/soft_delete_for_learning', { learning_id: payload.id });
    yield put(eduActions.fetchLearnings({ status: 'active', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Learning Deactivated Successfully.' })
  }, "Failed to delete learning");
}
function* deleteCircular({ payload }) {
  yield requestHandler(function* () {
    yield axios.post('/educators/soft_delete_for_circular', { circular_id: payload.id });
    yield put(eduActions.fetchCirculars({ status: 'active', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Circular Deactivated Successfully.' })
  }, "Failed to delete Circular");
}
function* deleteEvaluation({ payload }) {
  yield requestHandler(function* () {
    yield axios.post('/educators/soft_delete_for_evaluation', { evaluation_id: payload.id });
    yield put(eduActions.fetchEvaluations({ status: 'active', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Assessment Deactivated Successfully.' })
  }, "Failed to delete Assessment");
}
function* activateLearning({ payload }) {
  yield requestHandler(function* () {
    const body = new FormData()
    body.append('learning_id', payload.id);
    body.append('learning[status]', 'active');
    yield axios.put('/educators/edit_learning', body, axios.getFormDataContentType());
    yield put(eduActions.fetchLearnings({ status: 'deleted', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Learning activated successfully.' })
  }, "Failed to activate learning");
}
function* activateCircular({ payload }) {
  yield requestHandler(function* () {
    const body = new FormData()
    body.append('circular_id', payload.id);
    body.append('circular[status]', 'active');
    yield axios.put('/educators/edit_circular', body, axios.getFormDataContentType());
    yield put(eduActions.fetchCirculars({ status: 'deleted', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Circular activated successfully.' })
  }, "Failed to activate circular");
}
function* activateEvaluation({ payload }) {
  yield requestHandler(function* () {
    const body = { evaluation: { id: payload.id, status: 'active' } };
    yield axios.post('/educators/update_evaluation', body);
    yield put(eduActions.fetchEvaluations({ status: 'deleted', pageNo: 1 }))
    yield setAlertAndLoading(null, { message: 'Assement activated successfully.' })
  }, "Failed to activate assessment");
}

SagaRegistry.register(function* userSaga() {
  yield takeEvery("edu/fetchCirculars", fetchCirculars);
  yield takeEvery("edu/fetchEvaluations", fetchEvaluations);
  yield takeEvery("edu/fetchLearnings", fetchLearnings);
  yield takeEvery("edu/addNewCircular", addNewCircular);
  yield takeEvery("edu/addNewEvaluation", addNewEvaluation);
  yield takeEvery("edu/addNewLearning", addNewLearning);
  yield takeEvery("edu/updateCircular", updateCircular);
  yield takeEvery("edu/updateEvaluation", updateEvaluation);
  yield takeEvery("edu/updateLearning", updateLearning);
  yield takeEvery("edu/archiveCircular", archiveCircular);
  yield takeEvery("edu/archiveEvaluation", archiveEvaluation);
  yield takeEvery("edu/archiveLearning", archiveLearning);
  yield takeEvery("edu/unArchiveLearning", unArchiveLearning);
  yield takeEvery("edu/unArchiveCircular", unArchiveCircular);
  yield takeEvery("edu/unArchiveAssessment", unArchiveAssessment);
  yield takeEvery("edu/deleteCircular", deleteCircular);
  yield takeEvery("edu/deleteEvaluation", deleteEvaluation);
  yield takeEvery("edu/deleteLearning", deleteLearning);
  yield takeEvery("edu/activateCircular", activateCircular);
  yield takeEvery("edu/activateEvaluation", activateEvaluation);
  yield takeEvery("edu/activateLearning", activateLearning);
  yield takeEvery("edu/fetchAssessmentById", fetchAssessmentById);
  yield takeEvery("edu/fetchLearningById", fetchLearningById);
  yield takeEvery("edu/fetchCircularById", fetchCircularById);

})