import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NoAccessEval } from "../../components";
const ConsoleEndPoint = `https://console.${process.env.REACT_APP_MAIN_DOMAIN}`

export const NotAllowedScreen = (props) => {
  const { user } = props
  return (
    <div className='row w-100 h-100 no-access v-start'>
      <div className='col text-sec'>
        <h1 className='title exo2 '>CLANG!</h1>
        <p className='f2'>You are NOT authorised to access Fusion Controller.<br/>You should rather go to <Link to={`${ConsoleEndPoint}/login?sessionToken=${user.api_key}`}>Fusion Console</Link>.<br/>For any assistance contact your Organiser.</p>
      </div>
      <div className='img-sec col v-ctr'>
        <NoAccessEval className='eval' />
      </div>
    </div>
  )
}