import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LinkButton, DocumentTable, LearningDocViwer } from "../../../components";
import { Tabs } from 'antd';
import { ActivateDialog, } from "../../Common";
import { eduActions, getLearnings, getCurrentEduContent } from '../../../store';
const Columns = [
  { title: 'ID', dataIndex: 'learn_id', key: 'id', width: '120px', className: 'exo2 f9 text-center' },
  { title: 'Title', dataIndex: 'title', key: 'title', width: '41%', search: true, render: 'btn', variant: 'lite', color: '#0033CC', className: 'exo2 f9', supportServerSearch: true  },
  { title: 'Created', dataIndex: 'created_at', key: 'create_at', width: '18%', className: 'exo2 f9 text-center' },
  { title: 'Trigger After Days', dataIndex: 'trigger_after', key: 'trigger_after', width: '15%', className: 'exo2 f9 text-center' },
  { title: 'Action', dataIndex: 'action', key: 'action', width: '20%', className: 'exo2 f9 text-center', render: 'actions' }
]
const getTab = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get('tab') || 'active'
}
const TabItems = [
  { label: 'Active', key: 'active', status: 'active' },
  { label: 'Inactive', key: 'inactive', status: 'deleted' },
  { label: 'Archive', key: 'archive', status: 'archive' }
]
const getStatusByTab = (tabkey) => {
  let tab = TabItems.find((_) => (_.key === tabkey));
  return tab ? tab.status : 'active'
}
export const EducatorLearningListScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [state, setState] = useState({ selected: null, tab: getTab(), showDialog: '' })
  const learnings = useSelector(getLearnings.bind(null, state.tab));
  const learning = useSelector(getCurrentEduContent)
  const _filter = React.useRef({ pageNo: 1, search: '' })
  useEffect(() => {
    fetchLearnings()
  }, [state.tab])
  const handleContentSelect = (learning) => {
    dispatch(eduActions.setCurrentContent(null))
    if (learning) {
      dispatch(eduActions.fetchLearningById({ id: learning.guid || learning.id }))
    }
    setState((_) => ({ ..._, selected: learning }))
  }
  const handleActivate = (learning) => {
    setState((_) => ({ ..._, showDialog: '' }))
    dispatch(eduActions.activateLearning({ id: learning.id }))
  }
  const handleUnArchive = (learning) => {
    setState((_) => ({ ..._, showDialog: '' }))
    dispatch(eduActions.unArchiveLearning({ id: learning.id }))
  }
  const handleAction = (status, learning) => {
    let dialog = '';
    if (learning) {
      dialog = { learning }
    }
    if (status === 'active') {
      dialog.btnText = 'Activate';
      dialog.onConfirm = handleActivate.bind(null, learning)
      dialog.title = 'Activate Leaning!';
      dialog.description = 'Activated Learnings WILL be allocated to new Users. '
    } else if (status === 'unarchive') {
      dialog.btnText = 'Unarchive';
      dialog.onConfirm = handleUnArchive.bind(null, learning)
      dialog.title = 'Unarchive Leaning!';
      dialog.description = 'Unarchived Learnings WILL be allocated to new Users. '
    }
    setState((_) => ({ ..._, showDialog: dialog }))
  }
  const getActionsColumn = (learning) => {
    let status = learning.status || 'active';
    let actions = [];
    if (status === 'active') {
      actions = [{ label: 'Edit', variant: 'lite', color: '#0133CC', link: `${learning.guid}/edit` }]
    } else if (status === 'archive') {
      actions = [{ label: 'Unarchive', variant: 'lite', color: '#44BB44', onClick: handleAction.bind(null, 'unarchive', learning) }]
    } else {
      actions = [{ label: 'Activate', variant: 'lite', color: '#44BB44', onClick: handleAction.bind(null, 'active', learning) }]
    }
    return actions;
  }
  const getColumns = () => {
    const cols = Columns.map((_) => ({ ..._ }));
    cols[1].onClick = handleContentSelect;
    cols[4].getActions = getActionsColumn
    return cols;
  }
  const fetchLearnings = () => {
    const { pageNo, search } = _filter.current;
    dispatch(eduActions.fetchLearnings({ pageNo, search, status: getStatusByTab(state.tab) }))
    dispatch(eduActions.setCurrentContent(null))
  }
  const handlePageChange = (pageNo) => {
    _filter.current = { ..._filter.current, pageNo: pageNo }
    fetchLearnings()
  }
  const handleSearch = (colDataIndex, searchText) => {
    const search = { [colDataIndex]: searchText }
    _filter.current = { ..._filter.current, search };
    fetchLearnings();
  }
  return (
    <div className='row w-100 h-100 o-hide'>
      <div className='col f-rest h-100 o-hide screen-pad'>
        <div className='row docs-header h-btn'>
          <h4 className='exo2 f4 med'>Learning</h4>
          <LinkButton label="Create New" link="new" font='f9' />
        </div>
        <div>
          <Tabs
            activeKey={state.tab}
            onChange={(key) => {
              setState((_) => ({ ..._, tab: key }))
              navigate(`${window.location.pathname}?tab=${key}`)
            }}
            items={TabItems} />
        </div>
        <div className='row f-rest o-hide edu-list'>

          <div className='row w-100 h-100 o-hide v-start'>

            <div className='row f-rest h-100 o-hide'>
              <DocumentTable
                noSelection
                emptyMessage="No Learning contents yet"
                Columns={getColumns()}
                rows={learnings ? learnings.rows : null}
                totalCount={learnings ? learnings.totalCount : 0}
                onSearch={handleSearch}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      {
        Boolean(state.selected) &&
        <LearningDocViwer
          onClose={() => handleContentSelect(null)}
          learning={{ ...state.selected, ...(learning || {}) }} />
      }
      {
        Boolean(state.showDialog) &&
        <ActivateDialog
          {...state.showDialog}
          onClose={() => handleAction('', '')}
        />
      }
    </div>
  )
}