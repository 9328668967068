import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { eduActions, getCurrentEduContent, commonActions, AppActions, } from '../../../store';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DeactivateDialog, } from "../../Common";
import { Button, TextInput, Form, useNavigateBack, validateQuestion, EvaluationQuestion, QuestionDefaultValues, AssessmentOptions, findDuplicates } from "../../../components";

const Fields = [
  { label: 'Trigger After', attribute: 'trigger_after', className: "trigger", containerParentClassName: 'row', placeholder: "0-365", required: true, showColon: true, helpText: 'Days from New User Creation', helpTextClassName: 'f9 c00085 reg', inputContainerClassName: 'bgedf1f7', labelwidth: 'w-110px' },// changes are made to make trigger after as inputbox instead of select dropdown
]
const getQuestionsDataForForm = (questions) => {
  return questions.map((q) => {
    let _ = { ...q };
    _.answer = AssessmentOptions.find((o) => (o.id === _.answer))
    return _;
  })
}
export const EducatorNewEvaluationScreen = (props) => {
  const { isEdit } = props;
  const { asmtId } = useParams()
  const navigate = useNavigate();
  const assessment = useSelector(getCurrentEduContent)
  const dispatch = useDispatch()
  const location = useLocation();
  const navigateBack = useNavigateBack()
  const [state, setState] = useState({
    formData: {
      name: location && location.state && location.state.name ? location.state.name : "",
      // trigger_after: location && location.state && location.state.trigger_after ? getTriggerDays().find(({ id }) => (id === location.state.trigger_after)) : "",
      trigger_after: location && location.state && location.state.trigger_after ? location.state.trigger_after : "",
      questions: location && location.state && location.state.questions ? getQuestionsDataForForm(location.state.questions) : [{ ...QuestionDefaultValues }]
    },
    show: !isEdit,
    errors: location && location.state && location.state.errors ? location.state.errors : [],
    modified: false, showDialog: '', enableSave: false, validationError: ''
  })
  useEffect(() => {
    if (isEdit) {
      dispatch(eduActions.fetchAssessmentById({ evaluation_id: asmtId }))
    }
  }, [])
  useEffect(() => {
    if (isEdit && assessment) {
      if (assessment.status !== 'active') {
        return navigate('/page-not-found')
      }
      let formData = {
        name: assessment.name || assessment.title,
        // trigger_after: getTriggerDays().find(({ id }) => (id === assessment.trigger_after)),
        trigger_after: assessment.trigger_after,
        questions: getQuestionsDataForForm(assessment.questions)
      };
      setState((_) => ({ ..._, show: true, formData }))
    }
  }, [assessment])
  const handleBack = () => {
    if (state.modified) {
      return setState((_) => ({ ..._, showDialog: 'discord' }))
    }
    navigateBack()
  }
  const handlePublishContent = () => {
    const { formData } = state;
    let body = { evaluation: { title: formData.name, trigger_after: formData.trigger_after }, questions: [] };
    formData.questions.forEach((_, index) => {
      let answer = (typeof _.answer === "object") ? _.answer.id : _.answer;
      body.questions.push({ ..._, answer: answer, q_id: index + 1 })
    });
    let duplicateErrors = findDuplicates(body.questions);
    for (let i = 0; i < duplicateErrors.length; i++) {
      if (duplicateErrors[i]) {
        dispatch(AppActions.setAlert({ success: false, message: `Error in Question ${i + 1}` }))
        return setState((_) => ({ ..._, errors: duplicateErrors }))
      }
    }
    if (isEdit) {
      body.evaluation.id = Number(assessment.id);
      dispatch(eduActions.updateEvaluation(body))
    } else {
      dispatch(eduActions.addNewEvaluation(body))
    }
    navigateBack()
  }
  const handleValueChange = (e) => {
    let { name } = e.target, attributeVal, errors, validationError = '';
    const _formData = { ...state.formData };
    if (name === 'questions') {
      let { index, attribute, value } = e.target
      attributeVal = _formData.questions.map((_) => ({ ..._ }));
      attributeVal[index][attribute] = value;
      errors = findDuplicates(attributeVal);
    } else {
      attributeVal = e.target.value;
    }
    //this check is added to show warning to user to enter values in the range '0-365' only
    if (name === 'trigger_after') {
      const numericValue = parseInt(attributeVal, 10);
      if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 365) {
        attributeVal = String(numericValue);
        validationError = '';
      } else {
        attributeVal = '';
        validationError = 'Enter numeric values between 0-365 only'
      }
    }
    _formData[name] = attributeVal;
    setState((_) => ({ ..._, formData: { ..._formData }, errors: errors || [], modified: true, validationError }))
  }
  const isPublishEnabled = () => {
    let enabled = true, formData = state.formData, errors = state.errors.filter((_) => Boolean(_));
    if (errors.length > 0) {
      return false
    }
    if (!formData.name || !formData.trigger_after) {
      return false;
    }
    for (let i = 0; i < formData.questions.length; i++) {
      enabled = validateQuestion(formData.questions[i]);
      if (!enabled) {
        break
      }
    }
    return enabled;
  }
  const handleAddQuestion = () => {
    let questions = state.formData.questions.map((_) => ({ ..._ }));
    questions.push({ ...QuestionDefaultValues });
    setState((_) => ({ ..._, formData: { ..._.formData, questions: questions } }))
  }
  //this function is added to delete the question while creating or editing the assessment
  const handleDeleteQuestion = (index) => {
    let questions = state.formData.questions.map((q) => ({ ...q }));
    questions.splice(index, 1);
    let errors = findDuplicates(questions);
    setState((prevState) => ({ ...prevState, errors, formData: { ...prevState.formData, questions } }));
  }
  const handleDialogValue = (value) => {
    setState((_) => ({ ..._, showDialog: value }))
  }
  const handleDeleteContent = () => {
    dispatch(eduActions.deleteEvaluation({ id: assessment.id }));
    navigateBack()
  }
  //this function is added to the archive the assessment
  const handleArchiveContent = () => {
    dispatch(eduActions.archiveEvaluation({ id: assessment.id }));
    navigateBack()
  }
  return (
    <div className='col w-100 h-100 o-hide screen-pad new-content'>
      {
        state.show ?
          <React.Fragment>
            <div className='row header h-btn'>
              <div className='row'>
                <Button icon="f1 icon-back c00085" variant='lite' onClick={handleBack} />
                <TextInput name='name' autoFocus placeholder='New Assessment Name' font='f7' className='title-input bgedf1f7' value={state.formData.name} onChange={handleValueChange} />
              </div>
              <div className='row'>
                {
                  isEdit &&
                  <React.Fragment>
                    <Button onClick={() => handleDialogValue('archive')} label='Archive' className='bgCFE3333' />
                    <Button onClick={() => handleDialogValue('delete')} label='Deactivate' className='bgCFE3333' />
                  </React.Fragment>
                }
                <Button label='Publish' disabled={!isPublishEnabled()} onClick={handlePublishContent} />

              </div>
            </div>
            <div className='col f-rest w-100 oy-auto eval-container'>
              <Form
                validationError={state.validationError}
                fieldClass='circular-field'
                formData={{ ...state.formData }}
                Fields={Fields}
                // getOptions={() => {
                //   return getTriggerDays();
                // }}
                onChange={handleValueChange} />
              {
                state.formData.questions.map((question, index) => {
                  return (
                    <EvaluationQuestion
                      onChange={handleValueChange}
                      error={state.errors[index]}
                      key={index} question={question} index={index}
                      handleDeleteQuestion={handleDeleteQuestion} />
                  )
                })
              }
              <Button label='Add Question' onClick={handleAddQuestion} />
            </div>
          </React.Fragment>
          :
          null
      }
      <DeactivateDialog
        title='Deactivate this Assessment?'
        placeholder='Assessment ID'
        open={state.showDialog === 'delete'}
        onClose={() => handleDialogValue('')}
        compareFn={(id) => {
          return String(id).toLowerCase() === String(assessment.eval_id).toLowerCase()
        }}
        onDeactivate={handleDeleteContent}
        description='Deactivated Assessments WILL NOT be allocated to new Users. '
      />
      <DeactivateDialog
        btnText='Archive'
        title='Archive this Assessment?'
        placeholder='Assessment ID'
        open={state.showDialog === 'archive'}
        onClose={() => handleDialogValue('')}
        compareFn={(id) => {
          return String(id).toLowerCase() === String(assessment.eval_id).toLowerCase()
        }}
        onDeactivate={handleArchiveContent}
        description='Archived Assessment WILL NOT be allocated to new Users. '
      />
    </div>
  )
}