import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { FormDialog, Form } from "../../../components";
import { AppActions, CONTINUERSTATUS, ContinuerActions } from "../../../store";
const Fields = [
    { label: '', attribute: 'scenario_id', placeholder: "Scenario ID", type: 'text', autocomplete: "off" },
]
export const ActiveDialog = (props) => {
    const { blueprint, user, onClose } = props;
    const dispatch = useDispatch()
    const [state, setState] = useState({ formData: { scenario_id: '' }, error: {} })
    const validateForm = () => {
        let message;
        const { scenario_id } = state.formData
        if (blueprint.scenario_id !== scenario_id) {
            message = 'Invalid Scenario ID';
        }
        if (message) {
            dispatch(AppActions.setAlert({ success: false, message: message }))
        }
        return message
    }

    const handleValueChange = (e) => {
        const { error } = state, { name, value } = e.target;
        const formData = { ...state.formData }
        formData[name] = value;
        error[name] = ''
        setState((_) => ({ ..._, formData, error: { ...error } }))
        setTimeout(()=>{

        })
    }
    const handleUpdateStatus = () => {
        let msg = validateForm();
        if (!msg) {
            onClose(true);
            const status = CONTINUERSTATUS.ACTIVE;
            const message = 'Blueprint status has been Activated';
            dispatch(ContinuerActions.changeBlueprintStatus({ id: blueprint.id, status, message }))
        }
    }
    return (
        <FormDialog
            className={`activate-popup`}
            title={"Activate Scenario Blueprint?"}
            titleClass={`c009900 f6 med`}
            onClose={onClose.bind(null, false)}
            rightBtn={{ label: 'Activate', color: '#009900', font: 'f9', variant: 'lite', onClick: handleUpdateStatus}}
            leftBtn={{ label: "Cancel", font: 'f9', onClick: onClose.bind(null, false) }}
        >
            <div className='txt-justify f8 c00085'>
                <p>
                    This action will prevent further changes to this Scenario Blueprint.
                    Status will be changed to Active. Version Number will be frozen.
                    New Draft version will be created with the next Version Number.
                    Continuity Tests can be created immediately from this Blueprint.
                    Reversing this action is <b>NOT POSSIBLE.</b>
                </p>
                <p>
                    To confirm this action, type the Blueprint ID below.<br />
                    To cancel this action click Cancel.
                </p>
            </div>
            <div style={{ width: '150px' }}>
                <Form
                    fieldClass='field-input'
                    formData={state.formData}
                    error={state.error}
                    Fields={Fields}
                    onChange={handleValueChange}
                />
            </div>
        </FormDialog>
    )
}