import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

const initialState = {
  // circulars: null,
  activeCirculars: null,
  inactiveCirculars: null,
  archiveCirculars: null,
  activeLearnings: null,
  inactiveLearnings: null,
  archiveLearnings: null,
  activeEvaluations: null,
  inactiveEvaluations: null,
  archiveEvaluations: null,
  // learnings: null,
  // evaluations: null,
  currentContent: null
}


const eduSlice = createSlice({
  name: "edu",
  initialState: initialState,
  reducers: {
    fetchCirculars: () => { },
    fetchLearnings: () => { },
    fetchEvaluations: () => { },
    addNewCircular: () => { },
    addNewEvaluation: () => { },
    addNewLearning: () => { },
    activateCircular: () => { },
    activateEvaluation: () => { },
    activateLearning: () => { },
    updateCircular: () => { },
    updateEvaluation: () => { },
    updateLearning: () => { },
    archiveCircular: () => { },
    archiveEvaluation: () => { },
    archiveLearning: () => { },
    unArchiveLearning: () => {},
    unArchiveCircular: () => {},
    unArchiveAssessment: () => {},
    deleteCircular: () => { },
    deleteEvaluation: () => { },
    deleteLearning: () => { },
    fetchAssessmentById: () => { },
    fetchLearningById: () => { },
    fetchCircularById: () => { },
    setCirculars: (state, action) => {
      const { active, deleted, archive } = action.payload;
      if (active) {
        state.activeCirculars = active
      }
      if (deleted) {
        state.inactiveCirculars = deleted
      }
      if (archive) {
        state.archiveCirculars = archive
      }
    },
    setLearnings: (state, action) => {
      const { active, deleted, archive } = action.payload;
      if (active) {
        state.activeLearnings = active
      }
      if (deleted) {
        state.inactiveLearnings = deleted
      }
      if (archive) {
        state.archiveLearnings = archive
      }
    },
    setEvaluations: (state, action) => {
      const { active, deleted, archive } = action.payload;
      if (active) {
        state.activeEvaluations = active
      }
      if (deleted) {
        state.inactiveEvaluations = deleted
      }
      if (archive) {
        state.archiveEvaluations = archive
      }
    },
    setCurrentContent: (state, action) => {
      state.currentContent = action.payload
    }
  }
})
export const eduActions = eduSlice.actions;

ReducerRegistry.register("edu", eduSlice.reducer)