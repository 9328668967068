import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button, TextInput, Form, FormDialog, useNavigateBack, Menu, NMCKEditor, getEditorInitialData } from "../../../components";
import { Breadcrumbs, Link as MuiLink } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSelector, useDispatch } from "react-redux";
import { ArchiveDeleteDialog } from "./ArchiveDelete";
import { ActiveDialog } from "./ActiveDialog"
import { UpdateHistoryDialog } from "./History";
import {
  getDepartments, ContinuerActions, commonActions, getContinuerReviewers, getShowContinuerBlueprint, getCurrentUser,
  isContinuerActive, isContinuerArchived, isContinuerDraft, isContinuerApproved, CONTINUERSTATUS, getContinuerBlueprints
} from "../../../store"
import { AppActions, getDateInFormat } from "../../../store/app"

//Menus
const StatusMenus = [
  { dialogId: 'status', label: "Activate", iconElement: <CheckBoxIcon style={{ fontSize: '20px' }} />, selection: true, color: 'c999', disabled: true },
]
const ArchiveMenu = [
  { dialogId: 'archive', label: "Archive", icon: 'icon-open-folder', color: 'cFE3333', selection: true, disabled: false },
]
const UnarchiveMenu = [
  { dialogId: 'unarchive', label: "Unarchive", icon: 'icon-open-folder', color: 'cFE3333', selection: true },
]
const DeleteMenu = [
  { dialogId: 'delete', label: "Delete", icon: 'icon-delete', color: 'cFE3333', selection: true },
]
//Fields
const DefaultFormData = {
  name: "Blueprint Name"
}
const Fields = [
  { label: 'Department:', attribute: 'department', placeholder: "Department", required: true, type: 'select', labelFontSize: 'f7', labelClassName: 'w-130pix' },
]
const SendForReview = [
  { label: 'Reviewer', attribute: 'reviewer_email', type: 'select', placeholder: 'type Reviewer Name...', required: true, showColon: true, className: 'cdcdcdc', labelClassName: 'w-90pix', searchIcon: true, hideIcon: true }
]
//Sections
const Sections = [
  { title: "Internal Notes: ", attribute: 'internal_notes', initialData: getEditorInitialData(`<span style="color:#FF3333">&shy;</span>`) },
  { title: "Scenario: ", attribute: 'scenario', required: true },
  { title: "Expected Evidence: ", attribute: 'expected_evidence', required: true },
  { title: "Observation: ", attribute: 'observation', required: true },
  { title: "Result: ", attribute: 'result', required: true },
  { title: "Learning: ", attribute: 'learning', required: true },
  { title: "Reference: ", attribute: 'reference', required: true },
]

export const ContinuerControllerNewBluePrintScreen = (props) => {
  const { edit, readOnly, create } = props;
  const { blueprintId } = useParams()
  const navigateBack = useNavigateBack()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUser)
  const blueprint = useSelector(getShowContinuerBlueprint)
  const departments = useSelector(getDepartments)
  const reviewers = useSelector(getContinuerReviewers)
  const isArchive = isContinuerArchived(blueprint)
  const isActive = isContinuerActive(blueprint)
  const isDraft = isContinuerDraft(blueprint)
  const [state, setState] = useState({
    formData: { ...(blueprintId ? blueprint : '') }, modified: false,
    showDialog: '', show: !(edit || readOnly), selectedOption: false, blueprintNames: [], showActivateButton: false,
  })
  const ckRefs = useRef({})
  const activeBlueprints = useSelector(getContinuerBlueprints.bind(null, 'active'))
  const draftBlueprints = useSelector(getContinuerBlueprints.bind(null, 'draft'))
  const archiveBlueprints = useSelector(getContinuerBlueprints.bind(null, 'archive'))

  useEffect(() => {
    let dataFetchRemaining = false
    if (!activeBlueprints) {
      dataFetchRemaining = true
      dispatch(ContinuerActions.fetchContinuityBlueprints({ status: 'active' }))
    }
    if (!draftBlueprints) {
      dataFetchRemaining = true
      dispatch(ContinuerActions.fetchContinuityBlueprints({ status: 'draft' }))
    }
    if (!archiveBlueprints) {
      dataFetchRemaining = true
      dispatch(ContinuerActions.fetchContinuityBlueprints({ status: 'archive' }))
    }
    if (dataFetchRemaining === false) {
      setBlueprintNames()
    }
  }, [activeBlueprints, draftBlueprints, archiveBlueprints])

  useEffect(() => {
    dispatch(commonActions.fetchDepartments());
  }, [])

  useEffect(() => {
    if (departments) {
      if (create) {
        setState((_) => ({ ..._, show: true }))
      }
      else if (blueprintId) {
        dispatch(ContinuerActions.fetchContinuityBlueprint({ id: blueprintId }))
      }
    }
  }, [departments])
  useEffect(() => {
    if (blueprintId) {
      if (blueprint) {
        if (user.id === blueprint.reviewer_id) {
          setState((_) => ({ ..._, showActivateButton: true }))
        }

        if (blueprint.status === 'deleted' || blueprint.message === 'Record Not Found') {
          return navigate('/page-not-found')
        }
        const formData = { ...state.formData }
        formData.name = blueprint.title;
        Fields.forEach(({ attribute }) => {
          if (blueprint[attribute]) {
            formData[attribute] = blueprint[attribute]
          }
        })
        setState((_) => ({ ..._, formData: { ...formData }, show: true }));
      } else {
        setState((_) => ({ ..._, show: false }))
      }
    }
  }, [blueprint])

  const getFields = () => {
    let fields = Fields.slice(0, Fields.length - 1)
    return fields.map((_) => ({ ..._, readOnly: readOnly }))
  }
  const fields = getFields();
  const getOptions = (attribute) => {
    switch (attribute) {
      case 'department': return departments
      case 'reviewer_email': return reviewers
    }
    return null
  }
  const getSections = () => {
    let sections = [...Sections]
    return sections
  }
  const getMenu = () => {
    if (blueprint && !edit && !create) {
      if (isArchive) {
        // return [...UnarchiveMenu, ...DeleteMenu]
        return DeleteMenu
      }
      else {
        if (isDraft) {
          StatusMenus.forEach((_) => {
            if ((_.label === "Activate") && (state.showActivateButton)) {
              _.disabled = false
              _.color = 'c009900'
            }
          })
          return [...StatusMenus, { isEmpty: true }, ...ArchiveMenu, ...DeleteMenu]
        }
        else if (isActive) {
          return ArchiveMenu
        }
      }
    }
    return null
  }
  const handleDocsMenu = (e) => {
    setState((_) => ({ ..._, showMenu: e.target }))
  }
  const handleMenuClick = (e, menu) => {
    setState((_) => ({ ..._, showMenu: null, showDialog: menu.dialogId }))
  }
  const handleUnarchiveButtonClick = () => {
    setState((_) => ({ ..._, showDialog: 'unarchive' }))
  }

  const getBlueprintBody = () => {
    let body = { title: state.formData.name }
    Fields.forEach((field) => {
      if (field.type === 'html') {
        let editor = ckRefs.current[field.attribute];
        if (editor) {
          body[field.attribute] = editor.getData()
        }
      } else {
        body[field.attribute] = state.formData[field.attribute]
        if (field.type === 'select') {
          body[field.attribute] = state.formData[field.attribute].id
        }
      }
    })
    getSections().forEach((section) => {
      const editor = ckRefs.current[section.attribute]
      body[section.attribute] = editor.getData();
    })
    return body;
  }

  const handleNavBack = () => {
    navigateBack()
  }
  const handleBack = () => {
    if (!readOnly && state.modified && edit) {
      return setState((_) => ({ ..._, showDialog: 'discord' }))
    }
    handleNavBack()
  }
  const enableSave = () => {
    let enabled = false;
    if (state.modified) {
      const { formData } = state;
      enabled = Object.values(formData).every(value => {
        return value !== null && value !== undefined && value.toString().trim() !== '';
      });
      Fields.every(field => {
        if (field.required && !formData[field.attribute]) {
          enabled = false
        }
        return enabled
      })

      getSections().every(section => {
        const data = ckRefs.current[section.attribute] ? ckRefs.current[section.attribute].getData() : null
        if (!data) {
          enabled = false
        }
        return enabled
      })
    }
    return enabled
  }
  function extractTextFromHTML(htmlString) {
    let tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    tempElement.querySelectorAll('span').forEach(span => {
      span.innerHTML = span.innerHTML.replace(/&shy;/g, '');
    });
    let text = tempElement.textContent.trim();

    return text;
  }
  const handleValidateAndSave = () => {
    let isValid = true
    let message;
    let body = getBlueprintBody()
    Object.keys(body).forEach((key) => {
      let divToHighlight = document.getElementById(key)
      if ((key !== 'internal_notes') && (key !== 'title') && (key !== 'department')) {
        let extractedText = extractTextFromHTML(body[key])
        if (!extractedText || extractedText == '' || extractedText == ' ' || extractedText.length === 1) {
          isValid = false
          message = 'Please fill the required fields'
          dispatch(AppActions.setAlert({ success: false, message: message }))
          // Add highlighted class to CKEditor input box
          if (divToHighlight) {
            divToHighlight.classList.add('highlighted');
          }
        }
        else {
          // Remove highlighted class if text is not empty
          if (divToHighlight) {
            divToHighlight.classList.remove('highlighted');
          }
        }
      }
    })
    if (!edit) {
      for (let title of state.blueprintNames) {
        if (title === state.formData.name) {
          isValid = false
          message = 'Duplicate Blueprint Names are not allowed'
          break;
        }
      }
    }

    if (isValid) {
      handleSave()
    }
    else {
      dispatch(AppActions.setAlert({ success: false, message: message }))
    }
  }

  const handleSave = () => {
    if (edit && blueprintId) {
      setState((_) => ({ ..._, showDialog: 'history', updateHistory: { type: 'minor', summary: '' } }))
    } else {
      const body = getBlueprintBody()
      dispatch(ContinuerActions.createContinuerBlueprint({ continuer_blueprint: body }))
      handleDiscardDialog(true, null, true)
    }
  }
  const handleSaveWithUpdate = (htmlDesc) => {
    const body = getBlueprintBody()
    let updateHistory = { summary: htmlDesc };
    if (isContinuerApproved(blueprint)) {
      body.status = CONTINUERSTATUS.ACTIVE;
    }
    dispatch(ContinuerActions.updateContinuerBlueprintHistory({ blueprint: body, updateHistory, id: blueprint.id }))
    handleNavBack()
    setState((_) => ({ ..._, showDialog: '', modified: false }))
  }
  const handleUnarchive = () => {
    handleDiscardDialog(true);
    const status = CONTINUERSTATUS.DRAFT;
    dispatch(ContinuerActions.unarchiveBlueprint({ id: blueprint.id, status: status }))
  }
  const handleDiscardDialog = (back, e, isCreate) => {
    const showDialog = state.showDialog;
    setState((_) => ({ ..._, showDialog: '', reviewer_email: null }))
    if (back) {
      if (showDialog === 'delete' || showDialog === 'archive' || showDialog === 'unarchive' || isCreate) {
        let tab = ''
        if (isCreate) {
          tab = '?tab=draft';
        } else if (isArchive) {
          tab = '?tab=archive'
        } else {
          tab += '?tab=' + (isActive ? 'active' : 'draft');
        }
        setTimeout(() => {
          navigate("/C" + tab)
        }, 200)
      } else {
        handleNavBack()
      }
    }
  }

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    const formData = { ...state.formData }
    formData[name] = value;
    if (!edit) {
      dispatch(ContinuerActions.fetchNewBlueprintId(value.id))
    }
    setState((_) => ({ ..._, formData, modified: true }))
  }
  const handleEditorInstance = (attribute, e) => {
    ckRefs.current[attribute] = e.editor;
    if (Boolean(edit && blueprint)) {
      e.editor.setData(blueprint[attribute])
    }
  }
  const handleCKChange = (e, data) => {
    if (!state.modified) {
      setState((_) => ({ ..._, modified: true }))
    }
  }
  const menu = getMenu()
  const handleSendForReview = (e) => {
    dispatch(ContinuerActions.sendBlueprintForReview({ id: blueprint.id, email: state.reviewer_email.id }))
    setState((_) => ({ ..._, showDialog: '', reviewer_email: null }))
  }
  // Function to modify HTML to open links in new tab
  const modifyHtmlForLinks = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    const links = doc.querySelectorAll('a');

    links.forEach((link) => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
    });

    return doc.body.innerHTML;
  };
  const setBlueprintNames = () => {
    let temp = []
    if (activeBlueprints) {
      activeBlueprints.forEach((blueprint) => {
        temp.push(blueprint.title)
      })
    }
    if (draftBlueprints) {
      draftBlueprints.forEach((blueprint) => {
        temp.push(blueprint.title)
      })
    }
    if (archiveBlueprints) {
      archiveBlueprints.forEach((blueprint) => {
        temp.push(blueprint.title)
      })
    }
    setState((_) => ({ ..._, blueprintNames: [...temp] }))
  }
  return (
    <>
      <div className='col w-100 h-100 o-hide screen-pad-con new-content doc'>

        <>
          <Breadcrumbs aria-label="breadcrumb">
            {
              isActive && <MuiLink underline="hover" href="/C?tab=active"><div className='c0133CC f9'>Active /</div></MuiLink>
            }
            {
              isArchive && <MuiLink underline="hover" color="inherit" href="/C?tab=archive"> <div className='c0133CC f9'>Archive / </div></MuiLink>
            }
            {
              (isDraft || edit || create) && < MuiLink underline="hover" color="inherit" href="/C?tab=draft"><div className='c0133CC f9'>Draft /</div> </MuiLink>
            }

          </Breadcrumbs>
          <div className='row mt1 '>
            <div className='flex-grow1'>
              {(create) && !isArchive && !isActive ?
                <div className='row mt2'>
                  <TextInput name='name' label='Blueprint Name:' font='f7' labelClassName='w-170pix' className='w-500pix' labelTextClassName='exo2' value={state.formData.name} onChange={handleValueChange} required={true} placeholder='Enter Blueprint Name'
                  /></div>
                :
                <div className='row mt1' >
                  {blueprint && <div className='f4 bold exo2 c238787' >{blueprint.scenario_id}: {blueprint.title}</div>}
                </div>
              }
            </div>
            <div>
              <div className='row header h-btn mt1'>
                {
                  readOnly && !isArchive && !edit && !isActive &&
                  <Link to='edit' className='btn line-22 bg-primary'>
                    <span className='f9 cFFF'>Edit</span>
                  </Link>
                }
                {
                  ((readOnly) && !isArchive) && blueprint && isDraft &&
                  <Button label='Send for Review'
                    onClick={() => {
                      setState((_) => ({ ..._, showDialog: 'approver' }))
                      dispatch(ContinuerActions.fetchBlueprintReviewerList())
                    }}
                  />
                }
                {
                  isArchive &&
                  <Button label='Unarchive' onClick={handleUnarchiveButtonClick} />
                }
                {
                  Boolean(menu) &&
                  <Button className='col v-ctr h-ctr dot-menu btn-menu' icon="f9 icon-dot c00085" variant='lite' onClick={handleDocsMenu} />
                }
                <Menu
                  menuItems={menu}
                  anchorEl={state.showMenu}
                  onMenuClick={handleMenuClick}
                  onClose={() => handleDocsMenu({ target: null })} />
                <div className='row h-btn'>
                  {
                    ((!isArchive && edit) || create) &&
                    <Button label='Save' disabled={!enableSave()} onClick={handleValidateAndSave} className='m10' />
                  }
                </div>
              </div>
            </div>
          </div>

          <div className='shadow-box'></div>

          <div className='col f-rest oy-auto'>
            {
              (blueprint && !create) &&
              <>
                <div className='row mb1 f7 h-btn mt1 mb2'>
                  <div><span className='exo2'>Version :</span> <span className="field-editor"><b className='exo2'>{blueprint.version}</b></span></div>
                  <div ><span className='exo2'>Professional :</span> <span className="field-editor exo2"><b className='exo2'>{blueprint.audits[0].username}, {blueprint.audits[0].designation}</b></span></div>
                  <div className='pr4'><span className='exo2'>Date :</span> <span className="field-editor"><b className='exo2'>{getDateInFormat(blueprint.created_at)}</b></span></div>
                </div>
              </>
            }
            {create &&
              <div className='row mb1 f7 h-btn mt1 mb2'>
                <div><span className='exo2'>Version :</span> <span className="field-editor"><b className='exo2'>v101</b></span></div>
                <div ><span className='exo2'>Professional :</span> <span className="field-editor"><b className='exo2'>{`${user.firstname} ${user.lastname}, ${user.designation}, ${user.department_name}`}</b></span></div>
                <div className='pr4'><span className='exo2'>Date :</span> <span className="field-editor"><b className='exo2'>{getDateInFormat(new Date())}</b></span></div>
              </div>
            }
            {
              (create || edit) &&
              <div className=" field-row-con con-ck-container">
                <Form
                  getOptions={getOptions}
                  fieldClass={`field-input`}
                  formData={state.formData}
                  Fields={[...Fields]}
                  onInstanceReady={handleEditorInstance}
                  onChange={handleValueChange} />
              </div>
            }
            <div className="col con-editor-container">
              {blueprint && !create && !edit &&
                <div className=" f7 con-ck-container"> <span className='exo2'>Department :</span> <span className="field-editor exo2 bold">{blueprint.department}</span></div>
              }
              {
                getSections().map((section) => {
                  return (
                    <div className='col w-100 con-ck-container pr3' key={section.attribute} id={section.attribute}>
                      <h6 className='f7 exo2 c00085 med caps'>
                        {section.title}{section.required && <span className="cFE3333">*</span>}
                      </h6>
                      {
                        (readOnly && blueprintId && blueprint) ?
                          <React.Fragment>
                            {
                              Boolean(blueprint) && blueprint[section.attribute] &&
                              <div className='col default' dangerouslySetInnerHTML={{ __html: modifyHtmlForLinks(blueprint[section.attribute]) || '' }}>
                              </div>
                            }
                          </React.Fragment>
                          :
                          <>
                            <NMCKEditor
                              config={section.config}
                              title={section.title}
                              initialData={(edit && blueprint) ? (blueprint[section.attribute] || null) : section.initialData}
                              onChange={handleCKChange}
                              onInstanceReady={handleEditorInstance.bind(null, section.attribute)}
                            />
                          </>
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
          {
            state.showDialog === 'history' &&
            <UpdateHistoryDialog
              blueprint={blueprint}
              onSave={handleSaveWithUpdate}
              onClose={handleDiscardDialog.bind(null, false)}
              onDiscord={handleDiscardDialog.bind(null, true, null, true)}
            />
          }
          {
            state.showDialog === 'approver' &&
            <FormDialog
              title='Send for Review'
              titleClass='c238787 med f6 exo2'
              className='approval-dialog'
              onClose={handleDiscardDialog.bind(null, false)}
              leftBtn={{ label: "Cancel", color: '#0033CC', variant: 'lite', onClick: handleDiscardDialog.bind(null, false) }}
              rightBtn={{
                label: "Send",
                onClick: handleSendForReview,
                disabled: !state.selectedOption
              }} >
              <div className='col content'>
                <Form
                  fieldClass='approval-input'
                  formData={{ reviewer_email: state.reviewer_email }}
                  Fields={SendForReview}
                  getOptions={getOptions}
                  onChange={(e) => {
                    setState((_) => ({ ..._, reviewer_email: e.target.value, selectedOption: true }))
                  }}
                />
              </div>
            </FormDialog>
          }
          {
            Boolean(state.showDialog === 'delete' || state.showDialog === 'archive') &&
            <ArchiveDeleteDialog
              user={user}
              blueprint={blueprint}
              isDelete={state.showDialog === 'delete'}
              onClose={handleDiscardDialog} />
          }
          {
            state.showDialog === 'unarchive' &&
            <FormDialog
              className={`status-popup`}
              title={"Unarchive Scenario Blueprint?"}
              onClose={handleDiscardDialog.bind(null, false)}
              leftBtn={{ label: 'Unarchive', className: 'bgActive', onClick: handleUnarchive }}
              rightBtn={{ label: "Cancel", color: '#0033CC', variant: 'lite', onClick: handleDiscardDialog.bind(null, false) }}
            >
              <p className='f7 c00085'>
                This action will unarchive this Blueprint.<br />
                Its status will be updated to "Draft".<br />
              </p>
            </FormDialog>
          }
          {
            state.showDialog === 'status' &&
            <ActiveDialog
              user={user}
              blueprint={blueprint}
              onClose={handleDiscardDialog}
            />
          }
        </>
      </div >
    </>
  )
}