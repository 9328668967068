import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { eduActions, getCurrentUser, getCurrentEduContent, getTriggerDays } from '../../../store';
import { DeactivateDialog, SearchVariable, } from "../../Common";
import { Button, TextInput, useNavigateBack, Form, CircularTemplate, OvalLoading } from "../../../components";

const Fields = [
  { label: 'ID', attribute: 'cir_id', placeholder: "X####CRC", required: true, showColon: true, helpText: 'ID will be generated at creation', helpTextClassName: 'f10 c777', disabled: true, inputContainerClassName: 'bgedf1f7' },
  { label: 'Trigger After', attribute: 'trigger_after', className: "trigger ", containerParentClassName: 'row ', placeholder: "0-365", required: true, showColon: true, helpText: 'Days from New User Creation', helpTextClassName: 'f9 c00085 reg', inputContainerClassName: 'bgedf1f7' },
  { label: 'Title', attribute: 'title', placeholder: "Title", required: true, showColon: true, type: 'html', editorType: 'inline', className: 'single-line', allowFontSize: false, inputContainerClassName: 'bgedf1f7' },
  { label: 'Header Image', attribute: 'document', placeholder: "Choose File", type: 'file', accept: ".png,.jpg,.jpeg", required: true, showColon: true, selectLabel: 'Select File', inputContainerClassName: ' bgedf1f7' },
  { label: 'Information', attribute: 'information', placeholder: "Information", type: 'html', editorType: 'inline', required: true, showColon: true, rows: 5, cols: 10, allowFontSize: false, inputContainerClassName: 'bgedf1f7' },
  { label: 'Description', attribute: 'description', placeholder: "Description", type: 'html', editorType: 'inline', showColon: true, rows: 5, cols: 10, allowFontSize: false, inputContainerClassName: ' bgedf1f7' },
  { label: 'Eval', attribute: 'eval', placeholder: "Choose File", type: 'file', accept: ".png,.jpg,.jpeg", required: true, showColon: true, selectLabel: 'Select File', inputContainerClassName: ' bgedf1f7' },
  { label: 'Body Part 1', attribute: 'body_part1', placeholder: "Body Part 1", type: 'html', editorType: 'inline', required: true, showColon: true, rows: 5, cols: 10, allowFontSize: false, inputContainerClassName: 'bgedf1f7' },
  { label: 'Body Part 2', attribute: 'body_part2', placeholder: "Body Part 2", type: 'html', editorType: 'inline', required: true, showColon: true, rows: 5, cols: 10, allowFontSize: false, inputContainerClassName: ' bgedf1f7' },
  { label: 'Closing Message', attribute: 'closing_message', placeholder: "Closing Message", type: 'html', editorType: 'inline', required: true, showColon: true, className: 'single-line', allowFontSize: false, inputContainerClassName: ' bgedf1f7' },
  { label: 'Footer', attribute: 'footer', placeholder: "Footer", type: 'html', editorType: 'inline', required: true, showColon: true, rows: 5, cols: 10, allowFontSize: false, allowAddVariable: true, inputContainerClassName: 'bgedf1f7' },
  { label: 'Theme', attribute: 'theme_id', placeholder: "NM###THM", required: true, showColon: true, allowFontSize: false, inputContainerClassName: ' bgedf1f7', className: 'w-49' },
  { label: 'Content', attribute: 'content_id', placeholder: "NM###CRC", required: true, showColon: true, allowFontSize: false, inputContainerClassName: ' bgedf1f7', className: 'w-49' },
]
export const EducatorNewCircularScreen = (props) => {
  const { isEdit } = props
  const { circularId } = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editors = useRef({})
  const user = useSelector(getCurrentUser);
  const circular = useSelector(getCurrentEduContent)
  const navigateBack = useNavigateBack()
  const [state, setState] = useState({ show: !isEdit, modified: false, showDialog: '', enableSave: false, validationError: '' })
  const _formRef = useRef({ cir_id: 'X####CRC', eval: null, document: null })
  useEffect(() => {
    if (isEdit) {
      dispatch(eduActions.fetchCircularById({ id: circularId }))
    }
  }, [])
  useEffect(() => {
    if (isEdit && circular) {
      if (circular.status !== 'active') {
        return navigate('/page-not-found')
      }
      _formRef.current = {
        ...circular,
        // trigger_after: getTriggerDays().find(({ id }) => (id === circular.trigger_after)),
        eval: circular.attachments && circular.attachments[1] ? { name: circular.attachments[1].document_file_name } : null,
        document: circular.attachments ? { name: circular.attachments[0].document_file_name } : null
      }
      setState((_) => ({ ..._, show: true }))
    }
  }, [circular])
  const handleBack = () => {
    if (state.modified) {
      return setState((_) => ({ ..._, showDialog: 'discord' }))
    }
    navigateBack()
  }
  const handleValueChange = (e) => {
    let name = e.target.name, value = e.target.value, type = e.target.type;
    if (name === 'trigger_after') {
      const numericValue = parseInt(value, 10);
      if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 365) {
        _formRef.current[name] = numericValue;
        setState((_) => ({ ..._, validationError: '' }));

      } else {
        _formRef.current[name] = '';
        setState((_) => ({ ..._, validationError: 'Enter numeric values between 0-365 only' }));
      }
    }
    if (type === 'file') {
      value = e.target.files[0];
    }
    _formRef.current[name] = value;
    setState((_) => ({ ..._, enableSave: handleSaveBtn() }))
  }
  const handleSaveBtn = () => {
    let enabled = true, value, editor, _formData = { ..._formRef.current };
    if (!_formData.name) {
      return false;
    }
    for (let i = 0; i < Fields.length; i++) {
      const { type, attribute, required } = Fields[i];
      value = _formData[attribute]
      if (type === "html") {
        editor = editors.current[attribute];
        if (!editor) {
          enabled = false;
          break;
        }
        value = editor.getData();
      }
      if (required && !value) {
        enabled = false;
        break;
      }
    }
    return enabled
  }
  const handlePublishContent = () => {
    let body = getCircularData(true);
    if (isEdit) {
      body.append('circular_id', circular.id);
      dispatch(eduActions.updateCircular(body))
    } else {
      dispatch(eduActions.addNewCircular(body))
    }
    navigateBack()
  }
  const handleEditorInstance = (attribute, e) => {
    editors.current[attribute] = e.editor;
  }
  const getCircularData = (isFormData) => {
    let _formData = { ..._formRef.current }
    let body = isFormData ? new FormData() : {};
    if (isFormData) {
      body.append('circular[name]', _formData.name)
    } else {
      body.name = _formData.name
    }
    for (let i = 0; i < Fields.length; i++) {
      const { type, attribute } = Fields[i];
      let key = isFormData ? `circular[${attribute}]` : attribute, value = _formData[attribute];
      if (type === 'html' && editors.current[attribute]) {
        value = editors.current[attribute].getData()
      } else if (type === 'file') {
        key = attribute;
        value = value instanceof File ? value : null;
      } else if (type === 'select') {
        value = value ? value.id : value;
      }

      if (isFormData) {
        if (value !== null) {
          body.append(key, value)
        }
      } else {
        body[attribute] = value;
      }
    };
    if (!isFormData && isEdit) {
      if (!(body.eval instanceof File) && !(body.document instanceof File)) {
        body.attachments = circular.attachments
      }
    }
    return body
  }
  const handleCopyVariable = (variable) => {
    try {
      if (state.addVarAttribute) {
        if (editors.current[state.addVarAttribute]) {
          editors.current[state.addVarAttribute].insertText(variable.name)
        }
        handleDialogValue('')
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleDialogValue = (value) => {
    setState((_) => ({ ..._, showDialog: value }))
  }
  const handleDeleteContent = () => {
    dispatch(eduActions.deleteCircular({ id: circular.id }));
    navigateBack()
  }
  const handleArchiveContent = () => {
    dispatch(eduActions.archiveCircular({ id: circular.id }));
    navigateBack()
  }
  return (
    <div className='col w-100 h-100 o-hide screen-pad new-content'>
      {
        state.show ?
          <React.Fragment>
            <div className='row header h-btn'>
              <div className='row'>
                <Button icon="f1 icon-back c00085" variant='lite' onClick={handleBack} />
                <TextInput name='name' autoFocus placeholder='New Circular Name' font='f7' className='title-input bgedf1f7 ' value={_formRef.current.name} onChange={handleValueChange} />
              </div>
              <div className='row'>
                {
                  isEdit &&
                  <React.Fragment>
                    <Button onClick={() => handleDialogValue('archive')} label='Archive' className='bgCFE3333' />
                    <Button onClick={() => handleDialogValue('delete')} label='Deactivate' className='bgCFE3333' />
                  </React.Fragment>
                }
                <Button label={'Publish'} disabled={!state.enableSave} onClick={handlePublishContent} />
              </div>
            </div>
            <div className='col f-rest o-hide'>
              <div className='col h-100 oy-auto circular-form-container'>
                <div className='row v-start w-100'>
                  <div className='col form-section'>
                    <Form
                      validationError={state.validationError}
                      fieldClass='circular-field'
                      formData={{ ..._formRef.current }}
                      Fields={Fields.slice(0, 10)}
                      onInstanceReady={handleEditorInstance}
                      // getOptions={() => {
                      //   return getTriggerDays();
                      // }}
                      onChange={handleValueChange} />
                  </div>
                  <div className='circular-image'>
                    <CircularTemplate isTemplate user={user} circular={getCircularData()} />
                  </div>
                </div>
                <Form
                  fieldClass='circular-field'
                  formData={{ ..._formRef.current }}
                  Fields={Fields.slice(10)}
                  onInstanceReady={handleEditorInstance}
                  onChange={handleValueChange}
                  onAddVariable={(attribute) => (setState((_) => ({ ..._, showDialog: 'add-var', addVarAttribute: attribute })))} />
              </div>
            </div>
          </React.Fragment>
          :
          <OvalLoading />
      }
      {
        state.showDialog === 'add-var' &&
        <SearchVariable
          onClose={() => setState((_) => ({ ..._, showDialog: '' }))}
          onCopy={handleCopyVariable}
        />
      }
      <DeactivateDialog
        title='Deactivate Circular?'
        placeholder='Circular ID'
        open={state.showDialog === 'delete'}
        onClose={() => handleDialogValue('')}
        compareFn={(id) => {
          return String(id).toLowerCase() === String(circular.cir_id).toLowerCase()
        }}
        onDeactivate={handleDeleteContent}
        description='Deactivated Learnings WILL NOT be allocated to new Users.'
      />
      <DeactivateDialog
        btnText='Archive'
        title='Archive Circular?'
        placeholder='Circular ID'
        open={state.showDialog === 'archive'}
        onClose={() => handleDialogValue('')}
        compareFn={(id) => {
          return String(id).toLowerCase() === String(circular.cir_id).toLowerCase()
        }}
        onDeactivate={handleArchiveContent}
        description='Archived Circular WILL NOT be allocated to new Users. '
      />
    </div>
  )
}