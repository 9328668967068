import "./saga";
export * from "./slice";

const getContinuerState = (state) => state.Continuer;
export const CONTINUERSTATUS = {
  APPROVED: 'approved',
  ACTIVE: 'active',
  DELETED: 'deleted',
  ARCHIVED: 'archived',
  DRAFT: 'draft'
}
export const getContinuerStatusList = (state) => {
  const _state = getContinuerState(state);
  return _state.StatusList
}
export const getContinuerBlueprints = (status, state) => {
  const _state = getContinuerState(state);
  return status === 'active' ? _state.activeBlueprints : status === 'archive' ? _state.archiveBlueprints : _state.draftBlueprints;
}
export const getContinuerArchives = (status, state) => {
  const _state = getContinuerState(state);
  return _state.archiveBlueprints;
}
export const getContinuerBlueprint = (blueprintId, state) => {
  const _state = getContinuerState(state),
    finder = (_) => _.id === Number(blueprintId);
  let blueprint = _state.activeBlueprints.find(finder)
  if (!blueprint) {
    blueprint = _state.draftBlueprints.find(finder)
  }
  if (!blueprint) {
    blueprint = _state.archiveBlueprints.find(finder)
  }
  return blueprint
}
export const getShowContinuerBlueprint = (state) => {
  const _state = getContinuerState(state);
  return _state.showBlueprint
}
export const isContinuerArchived = (blueprint) => blueprint && (blueprint.status === 'archive' || blueprint.status === 'archived')

export const getContinuerBlueprintHistory = (state) => {
  const _state = getContinuerState(state);
  return _state.currentHistory
}
export const getNewContinuerDocumentId = (state) => {
  const _state = getContinuerState(state);
  return _state.newDocumentId
}
export const isContinuerDraft = (blueprint) => {
  return blueprint && (blueprint.status === 'draft' || blueprint.status === 'outline')
}
export const isContinuerApproved = (blueprint) => {
  return blueprint && blueprint.status === CONTINUERSTATUS.APPROVED
}
export const isContinuerActive = (blueprint) => {
  return blueprint && blueprint.status === CONTINUERSTATUS.ACTIVE
}
export const getContinuerChangeHistory = (state) => {
  const _state = getContinuerState(state);
  return _state.changeHistory;
}
export const getContinuerAuditHistory = (state) => {
  const _state = getContinuerState(state);
  return _state.auditHistory;
}
export const getContinuerDeleteStatus = (state) => {
  const _state = getContinuerState(state);
  return _state.deleteInProgress;
}
export const getContinuerReviewers = (state) => {
  const _state = getContinuerState(state);
  return _state.reviewers;
}