import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tabs } from 'antd';
import { ActivateDialog, } from "../../Common";
import { LinkButton, DocumentTable, CircularTemplateDialog } from "../../../components";
import { eduActions, getCirculars, getCurrentUser } from '../../../store';
const CircularColumns = [
  { title: 'ID', dataIndex: 'cir_id', key: 'id', width: '120px', className: 'exo2 f9 text-center' },
  { title: 'Title', dataIndex: 'name', key: 'name', width: '41%', search: true, render: 'btn', variant: 'lite', color: '#0033CC', className: 'exo2 f9', supportServerSearch: true },
  { title: 'Created', dataIndex: 'created_at', key: 'create_at', width: '18%', className: 'exo2 f9 text-center' },
  { title: 'Trigger After Days', dataIndex: 'trigger_after', key: 'trigger_after', width: '15%', className: 'exo2 f9 text-center' },
  { title: 'Action', dataIndex: 'action', key: 'action', width: '20%', className: 'exo2 f9 text-center', render: 'actions' }
]
const getTab = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get('tab') || 'active'
}
const TabItems = [
  { label: 'Active', key: 'active', status: 'active' },
  { label: 'Inactive', key: 'inactive', status: 'deleted' },
  { label: 'Archive', key: 'archive', status: 'archive' }
]
const getStatusByTab = (tabkey) => {
  let tab = TabItems.find((_) => (_.key === tabkey));
  return tab ? tab.status : 'active'
}
export const EducatorCircularListScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getCurrentUser);
  const [state, setState] = useState({ selected: null, tab: getTab() })
  const circulars = useSelector(getCirculars.bind(null, state.tab));
  const _filter = React.useRef({ pageNo: 1, search: '' })

  useEffect(() => {
    fetchCirculars()
  }, [state.tab])
  const handleCircularSelect = (circular) => {
    setState((_) => ({ ..._, selected: circular }))
  }
  const handleActivate = (circular) => {
    setState((_) => ({ ..._, showDialog: '' }))
    dispatch(eduActions.activateCircular({ id: circular.id }))
  }
  const handleUnArchive = (learning) => {
    setState((_) => ({ ..._, showDialog: '' }))
    dispatch(eduActions.unArchiveCircular({ id: learning.id }))
  }
  const handleAction = (status, circular) => {
    let dialog = '';
    if (circular) {
      dialog = { }
    }
    if (status === 'active') {
      dialog.btnText = 'Activate';
      dialog.onConfirm = handleActivate.bind(null, circular)
      dialog.title = 'Activate Circular!';
      dialog.description = 'Activated Circular WILL be allocated to new Users. '
    } else if (status === 'unarchive') {
      dialog.btnText = 'Unarchive';
      dialog.onConfirm = handleUnArchive.bind(null, circular)
      dialog.title = 'Unarchive Circular!';
      dialog.description = 'Unarchived Circular WILL be allocated to new Users. '
    }
    setState((_) => ({ ..._, showDialog: dialog }))
  }
  const getActionsColumn = (circular) => {
    let status = circular.status || 'active';
    let actions = [];
    if (status === 'active') {
      actions = [{ label: 'Edit', variant: 'lite', color: '#0133CC', link: `${circular.guid}/edit` }]
    } else if (status === 'archive') {
      actions = [{ label: 'Unarchive', variant: 'lite', color: '#44BB44', onClick: handleAction.bind(null, 'unarchive', circular) }]
    } else {
      actions = [{ label: 'Activate', variant: 'lite', color: '#44BB44', onClick: handleAction.bind(null, 'active', circular) }]
    }
    return actions;
  }
  const getColumns = () => {
    const cols = CircularColumns.map((_) => ({ ..._ }));
    cols[1].onClick = handleCircularSelect;
    cols[4].getActions = getActionsColumn
    return cols;
  }
  const fetchCirculars = () => {
    const { pageNo, search } = _filter.current;
    dispatch(eduActions.fetchCirculars({ pageNo, search, status: getStatusByTab(state.tab) }))
    dispatch(eduActions.setCurrentContent(null))
  }
  const handlePageChange = (pageNo) => {
    _filter.current = { ..._filter.current, pageNo: pageNo }
    fetchCirculars()
  }
  const handleSearch = (colDataIndex, searchText) => {
    const search = { [colDataIndex]: searchText }
    _filter.current = { ..._filter.current, search };
    fetchCirculars();
  }
  return (
    <div className='row w-100 h-100 o-hide'>
      <div className='col f-rest h-100 o-hide screen-pad'>
        <div className='row docs-header h-btn'>
          <h4 className='exo2 f4 med'>Circular</h4>
          <LinkButton label="Create New" link="new" font='f9' />
        </div>
        <div>
          <Tabs
            activeKey={state.tab}
            onChange={(key) => {
              setState((_) => ({ ..._, tab: key }))
              navigate(`${window.location.pathname}?tab=${key}`)
            }}
            items={TabItems} />
        </div>
        <div className='row f-rest o-hide edu-list'>
          <div className='row w-100 h-100 o-hide v-start'>
            <div className='row f-rest h-100 o-hide'>
              <DocumentTable
                noSelection
                emptyMessage="No Circular yet"
                Columns={getColumns()}
                rows={circulars ? circulars.rows : null}
                totalCount={circulars ? circulars.totalCount : 0}
                onSearch={handleSearch}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      {
        Boolean(state.selected) &&
        <CircularTemplateDialog
          isTemplate
          user={user}
          circular={state.selected}
          onClose={() => handleCircularSelect(null)} />
      }
      {
        Boolean(state.showDialog) &&
        <ActivateDialog
          {...state.showDialog}
          onClose={() => handleAction('', '')}
        />
      }
    </div>
  )
}