import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Menu, DocumentTable, Button } from "../../../components";
import { Tabs } from 'antd';
import { docActions, getContentBlueprints, getDeleteStatus } from "../../../store";
import { DocHistoryDialog } from "./History";
const ContentMenu = [
  { isLink: true, label: "Create New", icon: 'icon-plus-lite', to: 'new' }
]
const CommonColumns = [
  { title: 'Version', dataIndex: 'version', key: 'version', width: '80px', className: 'exo2 f9' },
  { title: 'ID', dataIndex: 'doc_id', key: 'id', width: '9%', className: 'exo2 f9 text-center' },
  { title: 'Blueprint', dataIndex: 'name', key: 'name', width: '43%', search: true, render: 'link', className: 'exo2 f9' },
  { title: 'Type', dataIndex: 'doc_type', key: 'doc_type', width: '110px',className: 'exo2 f9 caps' },
  { title: 'Department', dataIndex: 'department_name', key: 'department_name', width: '11%', className: 'exo2 f9 text-center' },
  { title: 'Classification', dataIndex: 'classification', key: 'classification', width: '10%', render: 'color', className: 'exo2 f9' },
  { title: 'Updated', dataIndex: 'updated_at', key: 'updated_at_str', width: '12%', className: 'exo2 f9 text-center' },
  { title: 'More', dataIndex: 'more', key: 'more', width: '8%', render: 'btn', variant: 'lite', color: '#0033CC', className: 'exo2 f9' },
]
const getTab = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get('tab') || 'active'
}
export const DocContentsScreen = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [state, setState] = useState({ showMenu: null, showDialog: '', selectedRows: [], tab: getTab() })
  const contents = useSelector(getContentBlueprints.bind(null, state.tab))
  const deleteStatus = useSelector(getDeleteStatus);
  const handleDocsMenu = (e) => {
    setState((_) => ({ ..._, showMenu: e.currentTarget }))
  }
  const handleMenuClick = (e) => {
    setState((_) => ({ ..._, showDocsMenu: e.currentTarget }))
  }
  useEffect(() => {
    dispatch(docActions.setShowBlueprint({blueprint: null}))
    dispatch(docActions.setNewDocId(''))
    if (!deleteStatus) {
      dispatch(docActions.fetchContentBlueprints())
    }
  }, [])
  const handleMoreInfo = (document) => {
    dispatch(docActions.setBlueprintAuditHistory([]))
    dispatch(docActions.setBlueprintChangeHistory([]))
    dispatch(docActions.fetchContentAudit({ id: document.id }))
    setState((_) => ({ ..._, showDialog: 'history', selectedDoc: document }))
  }
  const getColumns = () => {
    let columns = [...CommonColumns]
    columns[columns.length - 1] = { ...columns[columns.length - 1], onClick: handleMoreInfo }
    if (state.tab === 'active') {
      columns[2] = { ...columns[2], width: '33%' }
      const editorCol = { title: 'Edited', dataIndex: 'updater_listname', key: 'updater_listname', width: '12%', className: 'exo2 f9' };
      columns.splice(5, 0, editorCol)
    }
    return columns
  }
  return (
    <div className='col w-100 h-100 o-hide screen-pad'>
      <div className='row docs-header h-btn'>
        <h4 className='exo2 f4 med'>Content</h4>
        <Button className='col v-ctr h-ctr dot-menu btn-menu' icon="f9 icon-dot c00085" variant='lite' onClick={handleDocsMenu} />
        <Menu
          menuItems={ContentMenu}
          anchorEl={state.showMenu}
          onMenuClick={handleMenuClick}
          onClose={() => handleDocsMenu({ currentTarget: null })} />
      </div>
      <div className='col f-rest o-hide'>
        <Tabs
          activeKey={state.tab}
          onChange={(key) => {
            setState((_) => ({ ..._, tab: key }))
            navigate(`${window.location.pathname}?tab=${key}`)
          }}
          items={[{ label: 'Active', key: 'active' }, { label: 'Draft', key: 'draft' }]} />
        <div className='col f-rest o-hide'>
          <DocumentTable
            noSelection
            Columns={getColumns()}
            rows={contents}
            emptyMessage={state.tab === 'active' ? 'No Active Blueprints': 'No Draft Blueprints'}
          />
        </div>
      </div>
      {
        state.showDialog == 'history' &&
        <DocHistoryDialog
          onClose={() => {
            setState((_) => ({ ..._, showDialog: '', selectedDoc: null }))
            dispatch(docActions.setBlueprintAuditHistory(null))
            dispatch(docActions.setBlueprintChangeHistory(null))
          }}
          document={state.selectedDoc} />
      }
    </div>
  )
}