import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Menu, DocumentTable, Button } from "../../../components";
import { docActions, getPresentations, getDeleteStatus } from '../../../store';
const ContentMenu = [
  { isLink: true, label: "Create New", icon: 'icon-plus-lite', to: 'new' }
]
const PresentationColumns = [
  { title: 'Version', dataIndex: 'version', key: 'version', width: '9%', className: 'exo2 f9' },
  { title: 'Presentation', dataIndex: 'name', key: 'name', width: '41%', search: true, render: 'link', className: 'exo2 f9' },
  { title: 'Status', dataIndex: 'status', key: 'status', width: '8%', render: 'color', className: 'exo2 f9', sort: true },
  { title: 'Editor', dataIndex: 'updater_listname', key: 'updater_listname', width: '15%', className: 'exo2 f9' },
  { title: 'Creator', dataIndex: 'creator_listname', key: 'creator_listname', width: '15%', className: 'exo2 f9' },
  { title: 'Last Updated', dataIndex: 'updated_at', key: 'updated_at', width: '12%', className: 'exo2 f9 text-center' },
]
export const DocPresentationsScreen = (props) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({ showMenu: null, showDialog: '', selectedRows: [], tab: 'draft' })
  const presentations = useSelector(getPresentations)
  const deleteStatus = useSelector(getDeleteStatus);
  useEffect(() => {
    dispatch(docActions.setShowBlueprint({blueprint: null}))
    dispatch(docActions.setNewDocId(''))
    if (!deleteStatus) {
      dispatch(docActions.fetchPresentationBlueprints())
    }
  }, [])
  const handleDocsMenu = (e) => {
    setState((_) => ({ ..._, showMenu: e.currentTarget }))
  }
  return (
    <div className='col w-100 h-100 o-hide screen-pad'>
      <div className='row docs-header h-btn'>
        <h4 className='exo2 f4 med'>Presentation</h4>
        <Button className='col v-ctr h-ctr dot-menu btn-menu' icon="f9 icon-dot c00085" variant='lite' onClick={handleDocsMenu} />
        <Menu
          menuItems={ContentMenu}
          anchorEl={state.showMenu}
          onMenuClick={handleDocsMenu}
          onClose={() => handleDocsMenu({ currentTarget: null })} />
      </div>
      <div className='col f-rest present-bpr-tbl h-100 o-hide'>
        <DocumentTable
          noSelection
          emptyProps={{ height: 100 }}
          Columns={PresentationColumns}
          rows={presentations}
          emptyMessage='No Presentations'
        />
      </div>
      {/* <div className='col o-hide f-rest'>
        <h6 className='f2 exo2 text-center med'>ISMS Presentation Blueprint 216</h6>
        <PresentationTemplate />
      </div> */}
    </div>
  )
}