import React, {useState} from 'react';
import NeumetricLogoImg from "../../assets/images/neumetric-logo.png";
import ProfilePicImg  from "../../assets/images/profile_pic.png";
import Eval404Img from "../../assets/images/404-eval.png";
import LoadingOvalImg from "../../assets/images/Loading_Oval.png";
import LoadingGIFImg from "../../assets/images/Loading.gif";
import LogInOval from "../../assets/images/login-oval.png";
import NoAccessEvalImg from "../../assets/images/no-access.png";
//Logo
import AuditorLogoImg from "../../assets/images/auditor-logo.png";
import DocumenterLogoImg from "../../assets/images/documenter-logo.png";
import ControllerLogoImg from "../../assets/images/controller-logo.png";
//Icon
import ArcIconImg from "../../assets/images/arc-icon.png";
import AuditorIconImg from "../../assets/images/auditor-icon.png";
import ConsoleIconImg from "../../assets/images/console-icon.png";
import DocumentorIconImg from "../../assets/images/documenter-icon.png";
import DocumentorGrayIconImg from "../../assets/images/documenter-gray-icon.png";
import EducatorIconImg from "../../assets/images/educator-icon.png";
import EducatorGrayIconImg from "../../assets/images/educator-gray-icon.png";
import ControllerIconImg from "../../assets/images/controller-icon.png";
import InvestigatorIconImg from "../../assets/images/investigator-icon.png";
import WarningIconImg from "../../assets/images/warning.png";
import CircularBGImg from "../../assets/images/circular-bg.png";
import ContinuerIconImg from "../../assets/images/continuer-icon.png";
import ContinuerGrayIconImg from "../../assets/images/continuer-gray-icon.png";
import CentralIconImg from "../../assets/images/central-icon.png"

export const LogInOvalImg = (props) => {
  return (
    <img src={LogInOval} {...props} />
  )
}
export const ProfilePic = (props) => {
  return (
    <img src={ProfilePicImg} {...props} />
  )
}
export const Eval404 = (props) => {
  return (
    <img src={Eval404Img} {...props} />
  )
}
export const LoadingOval = (props) => {
  return (
    <img src={LoadingOvalImg} {...props} />
  )
}
export const LoadingGIF = (props) => {
  return (
    <img src={LoadingGIFImg} {...props} />
  )
}
//Logos
export const AuditorLogo = (props) => {
  return (
    <img src={AuditorLogoImg} {...props} />
  )
}
export const DocumenterLogo = (props) => {
  return (
    <img src={DocumenterLogoImg} {...props} />
  )
}
export const ControllerLogo = (props) => {
  return (
    <img src={ControllerLogoImg} {...props} />
  )
}
//Icons
export const AuditorIcon = (props) => {
  return (
    <img src={AuditorIconImg} {...props} />
  )
}
export const ArcIcon = (props) => {
  return (
    <img src={ArcIconImg} {...props} />
  )
}
export const ConsoleIcon = (props) => {
  return (
    <img src={ConsoleIconImg} {...props} />
  )
}
export const DocumentorIcon = (props) => {
  return (
    <img src={DocumentorIconImg} {...props} />
  )
}
export const DocumentorGrayIcon = (props) => {
  return (
    <img src={DocumentorGrayIconImg} {...props} />
  )
}
export const EducatorIcon = (props) => {
  return (
    <img src={EducatorIconImg} {...props} />
  )
}
export const InvestigatorIcon = (props) => {
  return (
    <img src={InvestigatorIconImg} {...props} />
  )
}
export const EducatorGrayIcon = (props) => {
  return (
    <img src={EducatorGrayIconImg} {...props} />
  )
}
export const ControllerIcon = (props) => {
  return (
    <img src={ControllerIconImg} {...props} />
  )
}
export const CentralIcon = (props) => {
  return (
    <img src={CentralIconImg} {...props} />
  )
}
export const NeumetricLogo = (props) => {
  return (
    <img src={NeumetricLogoImg} {...props} />
  )
}
export const CompanyLogo = (props) => {
  const { user } = props
  let [error, setError] = useState(false);
  return (
    <React.Fragment>
      {
        error ?
          <NeumetricLogo alt='company Logo' className='company' />
          :
          <img src={'https:' + (user.logo_url ? user.logo_url : (user.ent_org && user.ent_org.logo_url ? user.ent_org.logo_url : ''))} alt='company Logo' className='company' onError={() => setError(true)} />
      }
    </React.Fragment>
  )
}
export const WarningIcon = (props) => {
  return (
    <img src={WarningIconImg} {...props} />
  )
}
export const NoAccessEval = (props) => {
  return (
    <img src={NoAccessEvalImg} {...props} />
  )
}
export const CircularBG = (props) => {
  return (
    <img src={CircularBGImg} {...props} />
  )
}
export const ContinuerIcon = (props) => {
  return (
    <img src={ContinuerIconImg} {...props}/>
  )
}
export const ContinuerGrayIcon = (props) => {
  return (
    <img src={ContinuerGrayIconImg} {...props}/>
  )
}