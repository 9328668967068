import React, { useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { Tabs } from 'antd';
import { FormDialog, AppErrorBoundry, DocumentTable, NMCKEditor, OvalLoading, DocAuditTableCols, DocChangeTableCols } from "../../../components";
import { getContentChangeHistory, getCurrentUser, getDateInFormat, getContentAuditHistory } from "../../../store";

const Fields = [
  { label: 'Department', attribute: 'department_name', className: 'field', valClassName: 'bold caps' },
  { label: 'Type', attribute: 'doc_type', className: 'field', valClassName: 'bold caps' },
  { label: 'Creator', attribute: 'creator_listname', className: 'field', valClassName: 'bold caps' },
  { label: 'Created', attribute: 'created_at', className: 'field', valClassName: 'bold caps' },
  { label: 'Note', attribute: 'notes', className: 'w-100', valClassName: 'note txt-justify', type: 'html' }
]

export const DocHistoryDialog = (props) => {
  const { document } = props;
  const history = useSelector(getContentChangeHistory)
  const audits = useSelector(getContentAuditHistory)
  const [state, setState] = useState({ tab: 'change' })
  const getValue = (field) => {
    const value = document[field.attribute]
    return value && (typeof value === 'object') ? value.label : value
  }
  return (
    <FormDialog
      className='col-reverse history-dialog'
      leftBtn={{ label: "Close ", color: '#0133CC', onClick: props.onClose }}>
      <AppErrorBoundry>
        <div className='col content o-hide'>
          <h6 className='f8 bold title'>{document.name}</h6>
          <div className='row w-100 fwarp'>
            {
              Fields.map((_) => {
                let value = getValue(_)
                return (
                  <div className={`row gap v-start ${_.className}`}>
                    <label className='f9 label'>{_.label} : </label>
                    <div className={`f9 ${_.valClassName}`} dangerouslySetInnerHTML={{ __html: value }}></div>
                  </div>
                )
              })
            }
          </div>
          <div className='col history'>
            <Tabs
              activeKey={state.tab}
              onChange={(key) => {
                setState((_) => ({ ..._, tab: key }))
              }}
              items={[{ label: 'Change History', key: 'change' }, { label: 'Blueprint History', key: 'audit' }]} />
            {
              Array.isArray(history) ?
                <DocumentTable
                  noSelection
                  rows={(state.tab === 'change' ? history : audits) || []}
                  Columns={state.tab === 'change' ? DocChangeTableCols : DocAuditTableCols}
                  scrollOffset={150}
                />
                :
                <OvalLoading isSmall />
            }
          </div>
        </div>
      </AppErrorBoundry>
    </FormDialog>
  )
}

export const UpdateHistoryDialog = (props) => {
  const { blueprint } = props;
  const ref = useRef(null)
  const [disableSave, setDisableSave] = useState(true)
  const { _name, designation, department_name } = useSelector(getCurrentUser);
  const handleSaveWithUpdate = () => {
    const editor = ref.current;
    if (editor) {
      const text = editor.document.getBody().getText();
      props.onSave && props.onSave(editor.getData(), text);
    }
  }
  const handleCKChange = () => {
    const editor = ref.current;
    if (editor) {
      const text = editor.document.getBody().getText();
      let disable = text === '' || text === '\n'
      setDisableSave(disable)
    }
  }
  const handkleDiscord = () => {
    const editor = ref.current;
    if (editor) {
      editor.setData('')
    }
  }
  return (
    <FormDialog
      titleClass='c238787 bold exo2 f3'
      title='Update Blueprint History'
      className='search-var update-history'
      actions={[
        { label: "Discard", variant: 'lite', color: '#FE3333', onClick: handkleDiscord },
        { label: "Cancel", variant: 'lite', color: '#0133CC', onClick: props.onClose },
        {
          label: "Save", onClick: handleSaveWithUpdate,
          disabled: disableSave
        }
      ]}>
      <div className='col content'>
        <div className='row w-100 font-input text-input'>
          <label className='f9 c00085 label'>Responsible : </label>
          <div className='f9 input row f-rest'><span className='bold'>{_name}</span>, {designation} - {department_name}</div>
        </div>
        <div className='row w-100 h-btn'>
          <div className='row font-input text-input'>
            <label className='f9 c00085 label'>Date : </label>
            <div className='f9 input row f-rest bold'>{getDateInFormat()}</div>
          </div>
          <div className='row font-input text-input h-end'>
            <label className='f9 c00085 label'>Version : </label>
            <div className='f9 row f-rest input bold'>{blueprint.version}</div>
          </div>
        </div>
        <div className='col f-rest font-input text-input'>
          <label className='f9 c00085 label' style={{ marginBottom: '8px' }}>Description : </label>
          <NMCKEditor
            onChange={handleCKChange}
            onInstanceReady={(e) => ref.current = e.editor} />
        </div>
      </div>
    </FormDialog>
  )
}