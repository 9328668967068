import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { docActions, getArchives, getDeleteStatus } from "../../../store";
import { useNavigate } from "react-router-dom";
import { DocumentTable } from "../../../components";
import { DocHistoryDialog } from "./History";
import { Tabs } from 'antd';
const ContentColumns = [
  { title: 'Version', dataIndex: 'version', key: 'version', width: '80px', className: 'exo2 f9' },
  { title: 'ID', dataIndex: 'doc_id', key: 'id', width: '9%', className: 'exo2 f9 text-center' },
  { title: 'Blueprint', dataIndex: 'name', key: 'name', width: '43%', search: true, render: 'link', className: 'exo2 f9' },
  { title: 'Type', dataIndex: 'doc_type', key: 'doc_type', width: '110px',className: 'exo2 f9 caps' },
  { title: 'Department', dataIndex: 'department_name', key: 'department_name', width: '11%', className: 'exo2 f9 text-center' },
  { title: 'Classification', dataIndex: 'classification', key: 'classification', width: '10%', render: 'color', className: 'exo2 f9' },
  { title: 'Updated', dataIndex: 'updated_at', key: 'updated_at_str', width: '12%', className: 'exo2 f9 text-center' },
  { title: 'More', dataIndex: 'more', key: 'more', width: '8%', render: 'btn', variant: 'lite', color: '#0033CC', className: 'exo2 f9' },
]
const PresentationColumns = [
  { title: 'Version', dataIndex: 'version', key: 'version', width: '8%', className: 'exo2 f9' },
  { title: 'Presentation', dataIndex: 'name', key: 'name', width: '42%', search: true, render: 'link', className: 'exo2 f9' },
  { title: 'Status', dataIndex: 'status', key: 'status', width: '10%', render: 'color', className: 'exo2 f9' },
  { title: 'Editor', dataIndex: 'updater_listname', key: 'updater_listname', width: '15%', className: 'exo2 f9' },
  { title: 'Creator', dataIndex: 'creator_listname', key: 'creator_listname', width: '15%', className: 'exo2 f9' },
  { title: 'Last Updated', dataIndex: 'updated_at', key: 'updated_at', width: '10%', className: 'exo2 f9 text-center' },
]
const getTab = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get('tab') || 'content'
}
export const DocArchievesScreen = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [state, setState] = useState({ tab: getTab(), showDialog: '' })
  const archives = useSelector(getArchives.bind(null, state.tab))
  const deleteStatus = useSelector(getDeleteStatus);
  useEffect(() => {
    dispatch(docActions.setShowBlueprint({blueprint: null}))
    dispatch(docActions.setNewDocId(''))
    if (!deleteStatus) {
      dispatch(docActions.fetchContentBlueprints())
      dispatch(docActions.fetchPresentationBlueprints())
    }
  }, [])
  const handleMoreInfo = (document) => {
    dispatch(docActions.fetchContentAudit({ id: document.id }))
    setState((_) => ({ ..._, showDialog: 'history', selectedDoc: document }))
  }
  const getColumns = () => {
    let columns;
    if (state.tab === 'content') {
      columns = [...ContentColumns]
      columns[columns.length - 1] = { ...columns[columns.length - 1], onClick: handleMoreInfo }
    } else {
      columns = PresentationColumns
    }
    return columns
  }
  return (
    <div className='col w-100 h-100 o-hide screen-pad'>
      <div className='row docs-header h-btn'>
        <h4 className='exo2 f4 med'>Archive</h4>
      </div>
      <div className='col f-rest o-hide'>
        <Tabs
          activeKey={state.tab}
          onChange={(key) => {
            setState((_) => ({ ..._, tab: key }))
            navigate(`${window.location.pathname}?tab=${key}`)
          }}
          items={[{ label: 'Content', key: 'content' }, { label: 'Presentation', key: 'presentation' }]} />
        <DocumentTable
          noSelection
          rows={archives}
          Columns={getColumns()}
          emptyMessage='No Achived blueprints'
        />
      </div>
      {
        state.showDialog == 'history' &&
        <DocHistoryDialog
          onClose={() => {
            setState((_) => ({ ..._, showDialog: '', selectedDoc: null }))
            dispatch(docActions.setBlueprintChangeHistory(null))
            dispatch(docActions.setBlueprintAuditHistory(null))
          }}
          document={state.selectedDoc} />
      }
    </div>
  )
}