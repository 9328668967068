import React, { useRef, useEffect } from 'react';
import { CircularBG, CompanyLogo } from "../Assets";
import { FormDialog } from "../Dialog";
import { performVariableMapping, getPrimaryEmailAddress } from "../VariableMapping";
import "./style.scss";

export const CircularTemplate = (props) => {
  const { circular, user, isTemplate } = props;
  const _eval = useRef(null);
  const _tag = useRef(null);
  const onLoad = (reader, ref, e) => {
    ref.current.src = e.target.result;
    reader.removeEventListener("load", onLoad)
  }
  useEffect(() => {
    if (Array.isArray(circular.attachments)) {
      if (circular.attachments[0] && _tag.current) {
        _tag.current.src = `https:${circular.attachments[0].evidence_url}`
      }
      if (circular.attachments[1] && _eval.current) {
        _eval.current.src = `https:${circular.attachments[1].evidence_url}`
      }
    } else if ((circular.eval instanceof File) || (circular.document instanceof File)) {
      if (circular.eval && _eval.current) {
        let reader = new FileReader();
        reader.addEventListener("load", onLoad.bind(null, reader, _eval));
        reader.readAsDataURL(circular.eval);
      }
      if (circular.document && _tag.current) {
        let reader = new FileReader();
        reader.addEventListener("load", onLoad.bind(null, reader, _tag));
        reader.readAsDataURL(circular.document);
      }
    }
  }, [circular])
  console.log('user', props);
  return (
    <div className='col circular-template o-hide'>
      <CircularBG className="bg w-100 h-100" />
      <CompanyLogo user={user} />
      <div className='fg w-100 h-100'>
        <div className='title-container'>
          <div className='ck-container cFFF all title' dangerouslySetInnerHTML={{ __html: circular.title }}></div>
        </div>
        {
          (Array.isArray(circular.attachments) || Boolean(circular.document)) &&
          <img ref={_tag} alt='tag' className='tag' />
        }
        <p className='all infosec f12'>
          <span className='bold'>InfoSec Education Program</span><br />
          InfoSec, {user.ent_org.brandname}<br />
          Reach out to <span className='c0133CC'>infosec@{getPrimaryEmailAddress(user.ent_org)}</span> for anything related to <br />Information Security,
          Compliance & Privacy.
        </p>
        <p className='ck-container all info' dangerouslySetInnerHTML={{ __html: circular.information }}></p>
        <div className='ck-container all desc-body cFFF' >
          {
            (Array.isArray(circular.attachments) || Boolean(circular.eval)) &&
            <img ref={_eval} alt='Eval' className='eval' />
          }
          {
            Boolean(circular.description) && <span dangerouslySetInnerHTML={{ __html: circular.description }}></span>
          }
          <span className='list' dangerouslySetInnerHTML={{ __html: circular.body_part1 }}></span>
          <span className='list' dangerouslySetInnerHTML={{ __html: circular.body_part2 }}></span>
        </div>
        <div className='cFFF closing ck-container'>
          <span dangerouslySetInnerHTML={{ __html: circular.closing_message }} className='cell'></span>
        </div>
        <div className='col footer h-btn  o-hide'>
          <div className='col content w-100'>
            <p className='ck-container' dangerouslySetInnerHTML={{ __html: isTemplate ? circular.footer : performVariableMapping(circular.footer, { org: user.ent_org }) }}></p>
          </div>
          <div className='row cDDD f12 h-btn var-row w-100'>
            <span>&copy; Neumetric</span>
            <div className='row'>
              {/* <div className='var'>Scheduled: {isTemplate ? '[[YYMMDD]]' : circular.user_circular && circular.user_circular.scheduledAt}</div>
              <div className='var'>Due: {isTemplate ? '[[YYMMDD]]' : circular.user_circular && circular.user_circular.scheduledAt}</div> */}
              <div className='var'>Content: <span className='tup'>{circular.content_id}</span></div>
              <div className='var'>Theme: <span className='tup'>{circular.theme_id}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export const CircularTemplateDialog = (props) => {
  return (
    <FormDialog
      onClose={props.onClose}
      className='circular-preview-dialog' >
      <div className='col w-100 h-100 oy-auto no-scroll-bar'>
        <CircularTemplate circular={props.circular} user={props.user} isTemplate={props.isTemplate} />
      </div>
    </FormDialog>
  )
}