import React from 'react';
import "./style.scss";

export const ContinuerControllerHomeScreen = (props) => {
  return (
    <div className='row w-100 h-100 o-hide'>
      <div className='col f-rest h-100 o-hide bgWhite'>
        {props.children}
      </div>
    </div>
  )
}