import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { eduActions, AppActions } from '../../../store';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormDialog, TextInput, LinkButton, findDuplicates, QuestionDefaultValues, } from "../../../components";
import EvaluationTemplate from "../../../assets/others/Educator_Assessment_Upload_Template.xlsx"

const isEmptyRow = (row) => {
  let emptyCount = 0;
  for (let i = 0; i < row.length; i++) {
    let _ = row[i], blank = (_ === undefined || _ === '');
    if (!blank && i === 5) {
      blank = (String(_).toLowerCase() === "[chooseanswer]");
    }
    if (blank) {
      emptyCount++;
    }
  };
  return emptyCount === row.length;
}

const getQuestionFromUpload = (row, index) => {
  let question = { ...QuestionDefaultValues }, error = {};
  question.q_id = index + 1;
  if (row[0]) {
    question.stem = row[0]
  } else {
    error.stem = true
  }
  [1, 2, 3, 4].map((index) => {
    let key = 'option_' + (index)
    if (row[index]) {
      question[key] = row[index];
      if (row[5] === `Choice${index}`) {
        question.answer = key
      }
    } else {
      error[key] = true
    }
  })
  if (!question.answer) {
    error.answer = true
  }
  if (row[6]) {
    question.positive_mark = String(row[6] || '').trim();
    question.positive_mark = question.positive_mark ? Number(question.positive_mark) : NaN;
  }
  if (isNaN(question.positive_mark)) {
    question.positive_mark = ''
    error.positive_mark = true
  }
  if (row[7]) {
    question.negative_mark = String(row[7] || '').trim();
    question.negative_mark = question.negative_mark ? Number(question.negative_mark) : NaN;
  }
  if (isNaN(question.negative_mark)) {
    question.negative_mark = ''
    error.negative_mark = true
  }
  return { question, error }
}

export const UploadEvaluation = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [state, setState] = useState({ disableNext: true, tab: 'choose', name: '', trigger_after: '', validationError: '' })
  const _ref = useRef(null)
  const _file = useRef(null)
  const _res = useRef(null)
  const finalView = state.tab === 'view';
  const handleUploadQA = () => {
    if (finalView) {
      navigate("/E/A/new", { state: { ..._res.current, name: state.name, trigger_after: state.trigger_after, validationError: state.validationError } })
      handleClose()
      return
    }
    if (_file.current) {
      let reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        handleExcelData(data);
      };
      reader.readAsBinaryString(_file.current)
    }
  }
  const handleClose = () => {
    props.onClose && props.onClose()
  }
  const handleExcelData = (binary) => {
    let invalidCount = 0, finalQuestions = [], errors = [];
    const wsData = XLSX.read(binary, { type: 'binary' });
    const ws = wsData.Sheets[wsData.SheetNames[0]];
    let Questions = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false });
    if (Questions.length >= 3) {
      Questions = Questions.slice(1)
      Questions.forEach((q, index) => {
        if (!isEmptyRow(q)) {
          let { question, error } = getQuestionFromUpload(q, index);
          console.log("question, error", question, error);
          if (Object.keys(error).length !== 0) {
            invalidCount++
          }
          errors.push(error)
          finalQuestions.push(question)
        }
      });
      let dulicateTest = findDuplicates(finalQuestions);
      let isError = Array.isArray(dulicateTest) && dulicateTest.filter((_) => (Boolean(_))).length > 0;
      if (isError) {
        errors = dulicateTest;
      }
      if (invalidCount === 0 && !isError) {
        const body = { questions: finalQuestions }
        body.evaluation = { title: state.name, trigger_after: state.trigger_after };
        body.upload = true;
        dispatch(eduActions.addNewEvaluation(body))
        handleClose()
      }
      _res.current = { invalidCount, questions: finalQuestions, errors }
      setState((_) => ({ ..._, tab: 'view' }));
    } else {
      props.onClose()
      return dispatch(AppActions.setAlert({ success: false, message: 'Invalid CSV Format' }))
    }
  }
  const handleFileChange = async (e) => {
    e.preventDefault()
    let files = e.target.files;
    if (!files) {
      files = e.dataTransfer.files
    }
    if (files.length > 1) {
      props.onClose()
      return dispatch(AppActions.setAlert({ success: false, message: 'Muliple files are not supported' }))
    }
    _file.current = files[0];
    _res.current = null;
    let name = _file.current.name.split('.')[0]
    setState((_) => ({ ..._, name: name, disableNext: true, tab: 'edit' }))
  }
  const handleDragEnter = (e) => {
    e.preventDefault();
    return false
    // console.log('eover', e.dataTransfer.files[0], e.dataTransfer.items[0]);
  }
  const handleFileChoose = (e) => {
    if (_ref.current) {
      _ref.current.value = null
      _ref.current.click()
    }
  }
  const handleDownloadTemplate = (e) => {
    e.stopPropagation();
    return false
    // saveAs(EvaluationTemplate, "Educator_Assessment_Upload_Template.xlsx")
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    //   const _state = { ...state };
    //   _state[name] = value;
    //   setState((_) => ({ ..._, ..._state, disableNext: !Boolean(_state.name && _state.trigger_after) }))
    // 
    setState((prevState) => {
      const _state = { ...prevState };

      if (name === 'trigger_after') {
        const numericValue = parseInt(value, 10);
        if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 365) {
          _state[name] = numericValue;
          _state.validationError = '';
        } else {
          _state[name] = '';
          _state.validationError = 'Enter numeric values between 0-365 only';
        }
      }

      _state[name] = value;
      _state.disableNext = !Boolean(_state.name && _state.trigger_after);

      return { ..._state };
    });
  }
  const getNextButton = () => {
    let btn = { label: '', onClick: handleUploadQA }
    if (finalView && _res.current.invalidCount !== 0) {
      btn.label = 'View & Correct Error(s)';
    } else if (state.tab === 'choose') {
      btn = null
    } else if (state.tab === 'edit') {
      btn.label = 'Upload';
      btn.disabled = state.disableNext;
    }
    // console.log(btn);
    return btn;
  }
  return (
    <FormDialog
      onClose={props.onClose}
      TitleComponent={() => {
        return (
          <div className='row'>
            {finalView && <i className='icon-warning-icon cF4D27F f1 warning' />}
            <h6 className={`f4 bold exo2 ${finalView ? 'cFE3333' : 'c00085'}`}>{finalView ? 'Upload Error!' : 'Upload New Assessment'}</h6>
          </div>
        )
      }}
      className={`upload-doc-dialog ${state.tab !== 'choose' ? 'edit' : ''}`}
      leftBtn={finalView ? null : {
        label: 'Cancel',
        variant: 'lite',
        color: '#0133CC',
        onClick: handleClose
      }}
      rightBtn={getNextButton()}>
      {
        Boolean(state.tab === 'edit') &&
        <div className='col file-upload-section'>
          <span className='f8 reg'>Carefully specify the Assessment Title here.</span>
          <TextInput
            required
            value={state.name}
            className='title-input'
            placeholder='Assessment Title'
            name="name" label='CSV'
            onChange={handleChange} />
          {/* <Dropdown
            name='trigger_after'
            placeholder='0'
            className='trigger'
            label='Trigger After'
            helpText="Days from New User Creation"
            value={state.trigger_after}
            onChange={handleChange}
            options={getTriggerDays().map((_) => ({ ..._, value: _.id }))}
          /> */}
          <TextInput
            value={state.trigger_after}
            className='trigger'
            placeholder='0-365'
            name="trigger_after"
            label='Trigger After: '
            helpText="Days from New User Creation"
            helpTextClassName='f10 c00085 reg'
            validationError={state.validationError}
            onChange={handleChange} />
        </div>
      }
      {
        Boolean(state.tab === 'choose') &&
        <div
          className='col w-100 v-ctr h-ctr'
          draggable='true'
          onDragOver={handleDragEnter}
          onDrop={handleFileChange}
          onDragStart={handleDragEnter}>
          <div className='col files-drop-zone v-ctr h-ctr' onClick={handleFileChoose}>
            <i className='icon-drive c0133CC icon' />
            <h2 className='f8 c00085 reg text'>Click or drag your file here!</h2>
            <h2 className='f9 c00045 reg text'>Choose only one(1) csv file, multiple files are not supported</h2>
            <h4 className='f9 c00045 reg text'>You can download the Template <LinkButton font='f9' label="here" className='here' href={EvaluationTemplate} download="Educator_Assessment_Upload_Template.xlsx" target="_blank" onClick={handleDownloadTemplate} variant='lite' color="#0133CC" /></h4>
          </div>
          <input type='file' hidden ref={_ref} accept=".csv" multiple onChange={handleFileChange} />
        </div>
      }
      {
        finalView &&
        <div className='f8 c00085 file-upload-section'>Upload did not complete due to file error(s). Fix the error(s) & complete the upload.</div>
      }
    </FormDialog>
  )
}
