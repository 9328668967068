import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useLocation, } from "react-router-dom";
import { commonActions } from "../../../store";
import { ModuleInnerNavBar } from "../../../components";
import "./style.scss";
const DocumentsNavs = [
  { icon: 'icon-contents f7', link: 'C', tooltip: "Content",  },
  { icon: 'icon-presentations f9', link: 'P', tooltip: "Presentation" },
  { icon: 'icon-variables f9', link: 'V', tooltip: "Variable" },
  { icon: 'icon-open-folder-o f9', link: 'A', tooltip: "Archive" },
]
export const DocControllerHomeScreen = (props) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(commonActions.fetchDepartments())
    dispatch(commonActions.fetchDocumentTypes())
    dispatch(commonActions.fetchDocumentClassifications())
  }, [])
  return (
    <div className='row w-100 h-100 o-hide'>
      <ModuleInnerNavBar pathname={pathname} Items={DocumentsNavs} />
      <div className='col f-rest h-100 o-hide bgWhite'>
        {props.children}
      </div>
    </div>
  )
}