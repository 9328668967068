import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FormDialog, Form } from "../../../components";
import { docActions } from "../../../store";

const Fields = [
  { label: 'Variable', attribute: 'name', placeholder: "[[variable]]", required: true, showColon: true },
  { label: 'Meaning', attribute: 'meaning', placeholder: "Explanation of the variable.", type: 'textarea', rows: 4, required: true, showColon: true },
  { label: 'Source', attribute: 'source', placeholder: "Source", required: true, type: 'select', showColon: true },
]

export const AddVariableDialog = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ formData: {} })
  const handleCreateVariable = () => {
    const body = { ...state.formData }
    body.source = body.source.id;
    dispatch(docActions.addVariable(body))
    props.onClose && props.onClose();
  }
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    const { formData } = state
    formData[name] = value;
    setState((_) => ({ ..._, formData: { ...formData } }))
  }
  const getSourceOptions = () => {
    return [
      { id: 'console', label: 'Console' },
      { id: 'content', label: 'Content' },
      { id: 'presentation', label: 'Presentation' },
    ]
  }
  return (
    <FormDialog
      onClose={props.onClose} className="add-variable"
      rightBtn={{ label: "Create", onClick: handleCreateVariable }}
      leftBtn={{ label: "Cancel ", variant: 'lite', color: '#0133CC', onClick: props.onClose }}
      title={'Add Variable'} >
      <div className='col content'>
        <Form
          Fields={Fields}
          onChange={handleFormChange}
          formData={state.formData}
          getOptions={getSourceOptions}
        />
      </div>
    </FormDialog>
  )
}