import "./saga";
export * from "./slice";

const getEduState = (state) => state.edu;
export const getCirculars = (status, state) => {
  const _state = getEduState(state);
  return status === 'active' ? _state.activeCirculars : status === 'inactive' ? _state.inactiveCirculars : _state.archiveCirculars;
}
export const getLearnings = (status, state) => {
  const _state = getEduState(state);
  return status === 'active' ? _state.activeLearnings : status === 'inactive' ? _state.inactiveLearnings : _state.archiveLearnings;
}
export const getEvaluations = (status, state) => {
  const _state = getEduState(state);
  return status === 'active' ? _state.activeEvaluations : status === 'inactive' ? _state.inactiveEvaluations : _state.archiveEvaluations;
}
export const getCurrentEduContent = (state) => {
  const _state = getEduState(state);
  return _state.currentContent;
}
export const getTriggerDays = () => {
  return new Array(24).fill('').map((_, index) => {
    let val = (index + 1) * 15;
    return { id: val, label: val }
  })
}