import React, { useState, useRef, useEffect } from 'react';
import { Button, FormDialog, Form } from "../../components";
import { isProgress, getAllVariables, docActions } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const SearchFields = [
  { label: 'Variable Name', autoFocus: true, attribute: 'searchVariable', placeholder: "Search Variable", type: 'text', showColon: true }
]

export const SearchVariable = (props) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({ searchVariable: '', showVaribleList: false });
  const showProgress = useSelector(isProgress);
  const variables = useSelector(getAllVariables)
  const getFilteredVariables = () => {
    return (variables || []).filter(({ status, name }) => {
      return status.id === "active" && String(name).toLowerCase().includes(state.searchVariable.toLowerCase())
    })
  }
  const handleSearch = () => {
    if (!showProgress) {
      dispatch(docActions.fetchAllVariables())
    }
    setState((_) => ({ ..._, showVaribleList: true }))
  }
  const handleCopyVariable = (variable) => {
    try {
      props.onCopy && props.onCopy(variable);
      setState((_) => ({ ..._, searchVariable: '', showVaribleList: false }))
    } catch (error) {
      console.log(error);
    }
  }
  const filteredVariables = getFilteredVariables();
  return (
    <FormDialog
      title='Search Variable'
      className='search-var'
      onClose={props.onClose}
      rightBtn={{ label: "Cancel", color: '#0033CC', variant: 'lite', onClick: props.onClose }} >
      <div className='col content o-hide'>
        <Form
          fieldClass='field-input'
          formData={{ searchVariable: state.searchVariable }}
          Fields={SearchFields}
          onChange={(e) => setState((_) => ({ ..._, searchVariable: e.target.value, showVaribleList: false }))}
        />
        <Button label="Search" className='btn-search' onClick={handleSearch} disabled={!Boolean(state.searchVariable)} />
        <div className='col oy-auto list'>
          {
            showProgress &&
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          }
          {
            Boolean(state.searchVariable) && !showProgress && state.showVaribleList &&
            <>
              {
                filteredVariables.length === 0 ?
                  <div className='col v-ctr h-ctr'>
                    <h6 className='f9'>No Result Found</h6>
                  </div>
                  :
                  filteredVariables.map((variable) => {
                    return (
                      <div className='f9 c00085 w-100 row h-btn search-card' key={variable.name}>
                        <div className='col source'>{variable.source.label}</div>
                        <div className='col f-rest' style={{ flex: 2 }}>{variable.name}</div>
                        <div className='col f-rest h-ctr'>
                          <Button icon="icon-copy" variant='lite' className='f3' onClick={handleCopyVariable.bind(null, variable)} />
                        </div>
                      </div>
                    )
                  })
              }
            </>
          }
        </div>
      </div>
    </FormDialog>
  )
}