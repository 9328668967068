import React from 'react';
import { FormDialog } from "../Dialog";
import { OvalLoading } from "../Screens";
import { PDFViewer } from "../PDFViewer";
import VideoPlayer from 'react-player'
import "./style.scss";

export const LearningDocViwer = (props) => {
  const { learning } = props;
  const getVideoConfigs = () => {
    let config = {
      muted: false,
      volume: 0.1,
      controls: true,
      disablePictureInPicture: true,
      config: {
        file: {
          attributes: {
            controlsList: "nodownload noplaybackrate",
            disablePictureInPicture: true
          }
        }
      }
    }
    if (Boolean(learning.youtube_link) && learning.youtube_link.indexOf('https:') > -1) {
      config.poster = null;
      config.url = `${learning.youtube_link}`;
    } else if(Array.isArray(learning.attachments) && learning.attachments.length > 0){
      config.url = `https:${learning.attachments[0].evidence_url}`;
    }
    return config;
  }
  return (
    <FormDialog
      title={learning.name || learning.title}
      className='learning-template'
      onClose={props.onClose} >
      {
        Array.isArray(learning.attachments) ?
          <div className='col w-100 h-100 v-ctr oy-auto'>
            {
              learning.learn_type === 'video' &&
              <VideoPlayer {...getVideoConfigs()}
              />
            }
            {
              learning.learn_type === 'presentation' &&
              <PDFViewer
                {...learning.attachments[0]}
              />
            }
          </div>
          :
          <OvalLoading />
      }
    </FormDialog>
  )
}