import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { eduActions, getCurrentEduContent } from '../../../store';
import { useParams, useNavigate } from "react-router-dom";
import { DeactivateDialog, } from "../../Common";
import { Button, TextInput, useNavigateBack, Form, OvalLoading } from "../../../components";

const Fields = [
  { label: 'ID', attribute: 'learn_id', placeholder: "ID", required: true, showColon: true, helpText: 'ID will be generated at creation', helpTextClassName: 'f10 c777', disabled: true, inputContainerClassName: 'bgedf1f7' },
  { label: 'Type', attribute: 'learn_type', placeholder: "Type", type: 'select', required: true, showColon: true, inputContainerClassName: 'bgedf1f7' },
  { label: 'Trigger After', attribute: 'trigger_after', placeholder: "0-365", className: "trigger", containerParentClassName: 'row', required: true, showColon: true, helpText: 'Days from New User Creation', helpTextClassName: 'f9 c00085 reg', inputContainerClassName: 'bgedf1f7' },
  { label: 'Main Description', attribute: 'description', type: 'html', placeholder: "Description", required: true, showColon: true, backgroundColor: '#edf1f7' },//adding style as className is not working here
  { label: 'Upload File', attribute: 'document', placeholder: "Upload File", type: 'file', showColon: true, selectLabel: 'Select File', inputContainerClassName: 'bgedf1f7' },
  { label: 'Add YouTube Link', attribute: 'youtube_link', placeholder: "Add YouTube video link", type: 'link', showColon: true, inputContainerClassName: 'bgedf1f7' },
]
const Types = [
  { label: 'Presentation', id: 'presentation' },
  { label: 'Video', id: 'video' },
]

export const EducatorNewLearningScreen = (props) => {
  const { isEdit } = props
  const dispatch = useDispatch()
  const { learnId } = useParams()
  const navigate = useNavigate();
  const learning = useSelector(getCurrentEduContent)
  const navigateBack = useNavigateBack();
  const editors = useRef({})
  const _formData = useRef({ name: "", learn_id: "X####LRN", youtube_link: null, document: null })
  const [state, setState] = useState({ modified: false, showDialog: '', show: !isEdit, enableSave: false, validationError: '' })
  useEffect(() => {
    if (isEdit) {
      dispatch(eduActions.fetchLearningById({ id: learnId }))
    }
  }, [])
  useEffect(() => {
    if (isEdit && learning) {
      if (learning.status !== 'active') {
        return navigate('/page-not-found')
      }
      _formData.current = {
        ...learning,
        learn_type: Types.find(({ id }) => (id === learning.learn_type)),
        document: learning.attachments ? { name: learning.attachments[0].document_file_name } : null
      };
      setState((_) => ({ ..._, show: true }))
    }
  }, [learning])
  const handleBack = () => {
    if (state.modified) {
      return setState((_) => ({ ..._, showDialog: 'discord' }))
    }
    navigateBack()
  }
  const handlePublishContent = () => {
    const formData = _formData.current;
    const body = new FormData()
    body.append(`learning[name]`, formData.name);
    for (let i = 0; i < Fields.length; i++) {
      const { attribute, type } = Fields[i];
      let value = formData[attribute], key = `learning[${attribute}]`;
      if (type === 'select') {
        value = value.id
      }
      if (attribute === 'document') {
        key = attribute;
        value = value instanceof File ? value : null;
      }
      if (value) {
        body.append(key, value)
      }
    }
    if (isEdit) {
      body.append('learning_id', Number(learning.id))
      dispatch(eduActions.updateLearning(body))
    } else {
      dispatch(eduActions.addNewLearning(body))
    }
    navigateBack()
  }
  const handleValueChange = (e) => {
    let { name, value, type } = e.target;
    if (name === 'trigger_after') {
      const numericValue = parseInt(value, 10);
      if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 365) {
        _formData.current[name] = numericValue;
        setState((_) => ({ ..._, validationError: '' }));

      } else {
        _formData.current[name] = '';
        setState((_) => ({ ..._, validationError: 'Enter numeric values between 0-365 only' }));
      }
    }
    if (name === 'type') {
      _formData.current.document = null
    }
    if (type === 'file') {
      value = e.target.files[0]
    }
    _formData.current[name] = value;
    setState((_) => ({ ..._, enableSave: handleSaveBtn() }))
  }
  const handleSaveBtn = () => {
    let enabled = true, formData = _formData.current;
    if (!formData.name) {
      enabled = false;
    } else {
      for (let i = 0; i < Fields.length; i++) {
        const { attribute } = Fields[i];
        if (formData.required === true && !formData[attribute]) {
          enabled = false;
          break;
        }
      }
    }
    return enabled;
  }
  const handleEditorInstance = (attribute, e) => {
    editors.current[attribute] = e.editor;
  }
  const handleDialogValue = (value) => {
    setState((_) => ({ ..._, showDialog: value }))
  }
  const handleDeleteContent = () => {
    dispatch(eduActions.deleteLearning({ id: learning.id }));
    navigateBack()
  }
  const handleArchiveContent = () => {
    dispatch(eduActions.archiveLearning({ id: learning.id }));
    navigateBack()
  }
  return (
    <div className='col w-100 h-100 o-hide screen-pad new-content'>
      {
        state.show ?
          <React.Fragment>
            <div className='row header h-btn'>
              <div className='row'>
                <Button icon="f1 icon-back c00085" variant='lite' onClick={handleBack} />
                <TextInput name='name' autoFocus placeholder='New Learning Name' font='f7' className='title-input bgedf1f7' value={_formData.current.name} onChange={handleValueChange} />
              </div>
              <div className='row'>
                {
                  isEdit &&
                  <React.Fragment>
                    <Button onClick={() => handleDialogValue('archive')} label='Archive' className='bgCFE3333' />
                    <Button onClick={() => handleDialogValue('delete')} label='Deactivate' className='bgCFE3333' />
                  </React.Fragment>
                }
                <Button label='Publish' disabled={!state.enableSave} onClick={handlePublishContent} />
              </div>
            </div>
            <div className='col f-rest oy-auto learning-form-container circular-form-container'>
              <Form
                validationError={state.validationError}
                fieldClass='lean-field'
                formData={{ ..._formData.current }}
                Fields={
                  Fields.map((_) => {
                    let field = { ..._ };
                    if (field.attribute === 'document') {
                      field.accept = (_formData.current.learn_type && _formData.current.learn_type.id === 'video') ? 'video/*' : 'application/pdf'
                    }
                    return field
                  })

                }
                getOptions={(attribute) => {
                  switch (attribute) {
                    case 'learn_type': return Types;
                  }
                }}
                onInstanceReady={handleEditorInstance}
                onChange={handleValueChange} />
            </div>
          </React.Fragment>
          :
          <OvalLoading />
      }
      <DeactivateDialog
        title='Deactivate Learning?'
        placeholder='Learning ID'
        open={state.showDialog === 'delete'}
        onClose={() => handleDialogValue('')}
        compareFn={(id) => {
          return String(id).toLowerCase() === String(learning.learn_id).toLowerCase()
        }}
        onDeactivate={handleDeleteContent}
        description='Deactivated Learnings WILL NOT be allocated to new Users. '
      />
      <DeactivateDialog
        btnText='Archive'
        title='Archive Learning?'
        placeholder='Learning ID'
        open={state.showDialog === 'archive'}
        onClose={() => handleDialogValue('')}
        compareFn={(id) => {
          return String(id).toLowerCase() === String(learning.learn_id).toLowerCase()
        }}
        onDeactivate={handleArchiveContent}
        description='Archived Learnings WILL NOT be allocated to new Users. '
      />
    </div>
  )
}