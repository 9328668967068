import React, { useState, useRef, useEffect } from 'react';
import { FormDialog, TextInput, Button } from "../../components";

export const DeactivateDialog = (props) => {
  const { title, description, placeholder, compareFn, btnText } = props;
  const [state, setState] = useState({ id: '', error: '' })
  const handleChange = (e) => {
    setState((_) => ({ ..._, id: e.target.value, error: '' }))
  }
  const handleClose = () => {
    setState((_) => ({ ..._, id: '' }))
    props.onClose && props.onClose()
  }
  const handleDeactivate = () => {
    if ((typeof compareFn === 'function') && !compareFn(state.id)) {
      return setState((_) => ({ ..._, error: props.invalidMsg || `Invalid ${placeholder}` }))
    }
    props.onDeactivate && props.onDeactivate()
    handleClose()
  }
  return (
    <FormDialog
      title={title}
      open={props.open}
      className='deactive-dialog'
      titleClass='f4 exo2 bold cFE3333'
      onClose={handleClose}
      leftBtn={{ label: 'Cancel', variant: 'lite', color: '#26428b', onClick: handleClose }}
    >
      <div className='col'>
        <p className='f8 desc'>{description}</p>
        <p className='f8 desc'>Enter {placeholder} to confirm this change.</p>
        <div className='row h-btn input-row v-start'>
          <TextInput
            error={state.error}
            value={state.id}
            className='col f-rest'
            placeholder={placeholder}
            onChange={handleChange}
          />
          <Button label={btnText || 'Deactivate'} onClick={handleDeactivate} disabled={!Boolean(state.id)} className='bgCFE3333 btn-deactivate' />
        </div>
      </div>
    </FormDialog>
  )
}
export const ActivateDialog = (props) => {
  const { title, description, btnText, onConfirm } = props;
  const handleClose = () => {
    props.onClose && props.onClose()
  }
  return (
    <FormDialog
      title={title}
      open={props.open}
      className='deactive-dialog activate'
      titleClass='f4 exo2 bold c44bb44'
      onClose={handleClose}
      actions={[
        { label: 'Cancel', variant: 'lite', color: '#26428b', onClick: handleClose },
        { label: btnText || 'Activate', className: 'bg44bb44', onClick: onConfirm },
      ]}>
      <div className='col'>
        <p className='f8 desc'>{description}</p>
      </div>
    </FormDialog>
  )
}