import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

const StatusList = [
  { id: 'active', label: 'Active' },
  { id: 'inactive', label: 'Inactive' },
  { id: 'outline', label: 'Outline' },
  { id: 'draft', label: 'Draft' },
  { id: 'approve', label: 'Approved' },
  { id: 'archive', label: 'Archived' }
]

const initialState = {
  activeBlueprints: null,
  draftBlueprints: null,
  archiveBlueprints: null,
  changeHistory: null,
  StatusList,
  newBlueprintId: '',
  showBlueprint: null,
  deleteInProgress: false,
  auditHistory: null,
  reviewers: [],
}
const findBlueprintIndex = (list, id) => {
  return list.findIndex((_) => _.id === id)
}
const ContinuerSlice = createSlice({
  name: "Continuer",
  initialState: initialState,
  reducers: {
    createContinuerBlueprint: () => { },
    fetchContinuityBlueprints: () => { },
    fetchBlueprintAudit: () => { },
    fetchContinuityBlueprint: () => { },
    updateContinuerBlueprint: () => { },
    updateContinuerBlueprintHistory: () => { },
    fetchBlueprintReviewerList: () => { },
    sendBlueprintForReview: () => { },
    archiveBlueprint: () => { },
    unarchiveBlueprint: () => { },
    deleteBlueprint: () => { },
    changeBlueprintStatus: () => { },
    setBlueprintChangeHistory: (state, action) => {
      state.changeHistory = action.payload
    },
    setBlueprintAuditHistory: (state, action) => {
      state.auditHistory = action.payload
    },
    setBlueprints: (state, action) => {
      const { active, draft, archive } = action.payload
      if (active) {
        state.activeBlueprints = active
      }
      if (draft) {
        state.draftBlueprints = draft
      }
      if (archive) {
        state.archiveBlueprints = archive
      }
    },
    setBlueprintReviwerList: (state, action) => {
      state.reviewers = action.payload
    },
    setShowBlueprint: (state, action) => {
      const { blueprint } = action.payload;
      state.showBlueprint = blueprint
    },
    fetchNewBlueprintId: (state) => {
      state.newBlueprintId = ''
    },
    setNewBlueprintId: (state, action) => {
      state.newBlueprintId = action.payload
    },
    removeBlueprint: (state, action) => {
      const { id } = action.payload;
      let index
      let activeBlueprints = Array.isArray(state.activeBlueprints) ? [...state.activeBlueprints] : []
      let draftBlueprints = Array.isArray(state.draftBlueprints) ? [...state.draftBlueprints] : []
      let archiveBlueprints = Array.isArray(state.archiveBlueprints) ? [...state.archiveBlueprints] : [];
      index = findBlueprintIndex(activeBlueprints, id);
      if (index > -1) {
        activeBlueprints.splice(index, 1);
        state.activeBlueprints = [...activeBlueprints];
        return;
      }
      index = findBlueprintIndex(draftBlueprints, id);
      if (index > -1) {
        draftBlueprints.splice(index, 1);
        state.draftBlueprints = [...draftBlueprints];
        return;
      }
      index = findBlueprintIndex(archiveBlueprints, id);
      if (index > -1) {
        archiveBlueprints.splice(index, 1);
        state.archiveBlueprints = [...archiveBlueprints];
        return;
      }
    },
    setDeleteInProgress: (state, action) => {
      state.deleteInProgress = action.payload
    }
  }
})
export const ContinuerActions = ContinuerSlice.actions;

ReducerRegistry.register("Continuer", ContinuerSlice.reducer)