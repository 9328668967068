import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Menu, DocumentTable, Button } from "../../../components";
import { AddVariableDialog } from "./AddVariables";
import { docActions, getAllVariables } from "../../../store";

const VariableMenus = [
  { dialogId: 'create', label: "Create New", icon: 'icon-plus-lite' },
  { isEmpty: true },
]
const Columns = [
  { title: 'Variable', dataIndex: 'name', key: 'name', width: '15%', search: true, className: 'exo2 f9' },
  { title: 'Meaning', dataIndex: 'meaning', key: 'meaning', width: '65%', search: true, className: 'exo2 f9' },
  { title: 'Source', dataIndex: 'source', key: 'source', width: '10%', sort: true, className: 'exo2 f9' },
  { title: 'Status', dataIndex: 'status', key: 'status', width: '10%', render: 'color', sort: true, className: 'exo2 f9' },
]
export const DocVariablesScreen = (props) => {
  const dispatch = useDispatch()
  const variables = useSelector(getAllVariables)
  const [state, setState] = useState({ showMenu: null, showDialog: '', selectedRows: [] })
  const handleDocsMenu = (e) => {
    setState((_) => ({ ..._, showMenu: e.target }))
  }
  const handleMenuClick = (e, menu) => {
    setState((_) => ({ ..._, showMenu: null, showDialog: menu.dialogId }))
  }
  useEffect(() => {
    dispatch(docActions.fetchAllVariables())
  }, [])
  return (
    <div className='col w-100 h-100 o-hide screen-pad'>
      <div className='row docs-header h-btn'>
        <h4 className='exo2 f4 med'>Variable</h4>
        <Button className='col v-ctr h-ctr dot-menu btn-menu' icon="f9 icon-dot c00085" variant='lite' onClick={handleDocsMenu} />
        <Menu
          menuItems={VariableMenus}
          anchorEl={state.showMenu}
          onMenuClick={handleMenuClick}
          onClose={() => handleDocsMenu({ currentTarget: null })} />
      </div>
      <div className='col f-rest h-100'>
        <DocumentTable
          noSelection
          Columns={Columns}
          rows={variables}
          emptyMessage='No Variables'
        />
      </div>
      {
        state.showDialog === VariableMenus[0].dialogId &&
        <AddVariableDialog
          onClose={() => handleMenuClick(null, { dialogId: null })}
        />
      }
    </div>
  )
}