import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

const StatusList = [
    { id: 'active', label: 'Active' },
    { id: 'inactive', label: 'Inactive' },
    { id: 'outline', label: 'Outline' },
    { id: 'draft', label: 'Draft' },
    { id: 'approve', label: 'Approved' },
    { id: 'archive', label: 'Archived' }
]
const initialState = {
    activeGuides: null,
    draftGuides: null,
    archiveGuides: null,
    StatusList,
    newGuideId: '',
    showGuide: null,
    deleteInProgress: false,
    reviewers: [],
}
const findGuideIndex = (list, id) => {
    return list.findIndex((_) => _.id === id)
}
const GuideSlice = createSlice({
    name: "Guide",
    initialState: initialState,
    reducers: {
        createCentralGuide: () => { },
        fetchCentralGuides: () => { },
        fetchCentralGuide: () => { },
        updateCentralGuide: () => { },
        fetchGuideReviewerList: () => { },
        sendGuideForReview: () => { },
        activeGuide: () => { },
        archiveGuide: () => { },
        unarchiveGuide: () => { },
        deleteGuide: () => { },
        changeGuideStatus: () => { },
        setGuides: (state, action) => {
            const { active, draft, archive } = action.payload
            if (active) {
                state.activeGuides = active
            }
            if (draft) {
                state.draftGuides = draft
            }
            if (archive) {
                state.archiveGuides = archive
            }
        },
        setGuideReviwerList: (state, action) => {
            state.reviewers = action.payload
        },
        setShowGuide: (state, action) => {
            const { guide } = action.payload;
            state.showGuide = guide
        },
        fetchNewGuideId: (state) => {
            state.newGuideId = ''
        },
        setNewGuideId: (state, action) => {
            state.newGuideId = action.payload
        },
        removeGuide: (state, action) => {
            const { guide_id } = action.payload;
            let activeGuides = Array.isArray(state.activeGuides) ? [...state.activeGuides] : []
            let draftGuides = Array.isArray(state.draftGuides) ? [...state.draftGuides] : []
            let archiveGuides = Array.isArray(state.archiveGuides) ? [...state.archiveGuides] : [];
            [activeGuides, draftGuides, archiveGuides].forEach(list => {
                const index = state[list].findIndex(guide => guide.guide_id === guide_id);
                if (index > -1) {
                    state[list].splice(index, 1);
                }
            });
        },
        setDeleteInProgress: (state, action) => {
            state.deleteInProgress = action.payload
        }
    }
})
export const GuideActions = GuideSlice.actions;
ReducerRegistry.register("Guide", GuideSlice.reducer)
