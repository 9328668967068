import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { InfoCircleOutlined, CheckCircleOutlined, WarningOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button } from "../Button";
import { AppErrorBoundry } from "../Screens";
import "./style.scss";

export const TransparentDialog = (props) => {
  return (
    <Dialog open classes={{ paper: `transparent-dialog ${props.className || ''}` }}>
      <DialogContent classes={{ root: 'dialog-container' }}>
        {props.children}
      </DialogContent>
    </Dialog>
  )
}
export const FormDialog = (props) => {
  const { title, leftBtn, rightBtn, actions, titleClass, TitleComponent } = props;
  let open = props.open === undefined ? true : props.open
  return (
    <Dialog open={open} onClose={props.onClose} classes={{ paper: `form-dialog o-hide ${props.className || ''}`, root: 'dialog-root' }}>
      <DialogContent classes={{ root: 'col dialog-container o-hide' }}>
        {
          Boolean(title) ?
            <div className='col title-sec'>
              <h6 className={`${titleClass || 'f6 med'} exo2`}>{title}</h6>
            </div>
            :
            Boolean(TitleComponent) ?
              <TitleComponent />
              : null
        }
        <AppErrorBoundry className='col oy-auto'>
          {props.children}
        </AppErrorBoundry>
      </DialogContent>
      <DialogActions className='dialog-actions pad'>
        {
          Boolean(leftBtn) &&
          <Button {...leftBtn} />
        }
        {
          Boolean(rightBtn) &&
          <Button {...rightBtn} />
        }
        {
          Array.isArray(actions) &&
          actions.map((_, i) => {
            return <Button {..._} key={i} />
          })
        }
      </DialogActions>
    </Dialog>
  )
}
export const ConfirmPopup = (props) => {
  const { title, severity, titleClassName, description, onConfirm, onCancel, open } = props;
  const btnOk = props.btnOk || { text: 'OK' };
  const btnCancel = props.btnCancel || { text: 'Cancel' };
  const getIcon = () => {
    if (severity === 'success') {
      return <CheckCircleOutlined />
    } else if (severity === 'info') {
      return <InfoCircleOutlined />
    } else if (severity === 'warning') {
      return <WarningOutlined />
    } else if (severity === 'error') {
      return <CloseCircleOutlined />
    }
    return null
  }
  useEffect(() => {
    if (open) {
      Modal.confirm({
        title: (
          <span className={`f7 ${titleClassName || ''}`}>{title}</span>
        ),
        icon: getIcon(),
        className: `confirm-popup ${severity}`,
        okText: btnOk.text,
        cancelText: btnCancel.text,
        okButtonProps: { className: `f9 ${btnOk.className}` },
        cancelButtonProps: { className: `f9 ${btnCancel.className}` },
        onOk: onConfirm,
        onCancel: onCancel,
        content: (
          <React.Fragment>
            {
              Boolean(description) &&
              <p className='desc f9 c00085 '>{description}</p>
            }
          </React.Fragment>
        )
      })
    }
  }, [open])
  return (
    null
  )
}