import ControllerRouter from "./routers";
import { Provider } from "react-redux";
import { createAppStore } from "./store";
import { App } from 'antd';
import './App.scss';
function DocumenterApp() {
  return (
    <App className="col w-100 h-100 o-hide">
      <Provider store={createAppStore()}>
        <ControllerRouter />
      </Provider>
    </App>
  );
}

export default DocumenterApp;
