import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tabs } from 'antd';
import { ActivateDialog, } from "../../Common";
import { LinkButton, DocumentTable, EvaluationAssementor, Button } from "../../../components";
import { eduActions, getEvaluations, getCurrentEduContent } from '../../../store';
import { UploadEvaluation } from "./Upload";

const EvaluationColumns = [
  { title: 'ID', dataIndex: 'eval_id', key: 'eval_id', width: '120px', className: 'exo2 f9 text-center' },
  { title: 'Title', dataIndex: 'title', key: 'title', width: '41%', search: true, render: 'btn', variant: 'lite', color: '#0033CC', className: 'exo2 f9', supportServerSearch: true },
  { title: 'Created', dataIndex: 'created_at', key: 'create_at', width: '18%', className: 'exo2 f9 text-center' },
  { title: 'Trigger After(days)', dataIndex: 'trigger_after', key: 'trigger_after', width: '15%', className: 'exo2 f9 text-center' },
  { title: 'Action', dataIndex: 'action', key: 'action', width: '20%', className: 'exo2 f9 text-center', render: 'actions' }
]
const TabItems = [
  { label: 'Active', key: 'active', status: 'active' },
  { label: 'Inactive', key: 'inactive', status: 'deleted' },
  { label: 'Archive', key: 'archive', status: 'archive' }
]

const getStatusByTab = (tabkey) => {
  let tab = TabItems.find((_) => (_.key === tabkey));
  return tab ? tab.status : 'active'
}
const getTab = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get('tab') || 'active'
}
export const EducatorEvaluationListScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({ selected: null, showDialog: '', tab: getTab() })
  const evaluations = useSelector(getEvaluations.bind(null, state.tab));
  const evaluation = useSelector(getCurrentEduContent);
  const _filter = React.useRef({ pageNo: 1, search: '' })

  useEffect(() => {
    fetchAssessments()
  }, [state.tab])

  const handleEvaluationSelect = (evaluation) => {
    dispatch(eduActions.setCurrentContent(null))
    dispatch(eduActions.fetchAssessmentById({ evaluation_id: evaluation.id }))
    setState((_) => ({ ..._, selected: evaluation }))
  }
  const handleDialog = (val) => {
    setState((_) => ({ ..._, showDialog: val }))
  }
  const handleActivate = (evaluation) => {
    setState((_) => ({ ..._, showDialog: '' }))
    dispatch(eduActions.activateEvaluation({ id: evaluation.id }))
  }
  const handleUnArchive = (learning) => {
    setState((_) => ({ ..._, showDialog: '' }))
    dispatch(eduActions.unArchiveAssessment({ id: learning.id }))
  }
  const handleAction = (status, evaluation) => {
    let dialog = '';
    if (evaluation) {
      dialog = {}
    }
    if (status === 'active') {
      dialog.btnText = 'Activate';
      dialog.onConfirm = handleActivate.bind(null, evaluation)
      dialog.title = 'Activate Assessment!';
      dialog.description = 'Activated Assessment WILL be allocated to new Users. '
    } else if (status === 'unarchive') {
      dialog.btnText = 'Unarchive';
      dialog.onConfirm = handleUnArchive.bind(null, evaluation)
      dialog.title = 'Unarchive Assessment!';
      dialog.description = 'Unarchived Assessment WILL be allocated to new Users. '
    }
    setState((_) => ({ ..._, showDialog: dialog }))
  }
  const getActionsColumn = (evaluation) => {
    let status = evaluation.status || 'active';
    let actions = [];
    if (status === 'active') {
      actions = [{ label: 'Edit', variant: 'lite', color: '#0133CC', link: `${evaluation.guid}/edit` }]
    } else if (status === 'archive') {
      actions = [{ label: 'Unarchive', variant: 'lite', color: '#44BB44', onClick: handleAction.bind(null, 'unarchive', evaluation) }]
    } else {
      actions = [{ label: 'Activate', variant: 'lite', color: '#44BB44', onClick: handleAction.bind(null, 'active', evaluation) }]
    }
    return actions;
  }
  const getColumns = () => {
    const cols = EvaluationColumns.map((_) => ({ ..._ }));
    cols[1].onClick = handleEvaluationSelect;
    cols[4].getActions = getActionsColumn
    return cols;
  }
  const fetchAssessments = () => {
    const { pageNo, search } = _filter.current;
    dispatch(eduActions.fetchEvaluations({ pageNo, search, status: getStatusByTab(state.tab) }))
    dispatch(eduActions.setCurrentContent(null))
  }
  const handlePageChange = (pageNo) => {
    _filter.current = { ..._filter.current, pageNo: pageNo }
    fetchAssessments()
  }
  const handleSearch = (colDataIndex, searchText) => {
    const search = { [colDataIndex]: searchText }
    _filter.current = { ..._filter.current, search };
    fetchAssessments();
  }
  return (
    <div className='row w-100 h-100 o-hide screen-pad'>
      <div className='col f-rest h-100 o-hide'>
        <div className='row docs-header h-btn'>
          <h4 className='exo2 f4 med'>Assessment</h4>
          <div className='row'>
            <Button label="Upload New" font='f9' className="btn-new" onClick={() => handleDialog('upload')} />
            <LinkButton label="Create New" link="new" font='f9' />
          </div>
        </div>
        <div>
          <Tabs
            activeKey={state.tab}
            onChange={(key) => {
              setState((_) => ({ ..._, tab: key }))
              navigate(`${window.location.pathname}?tab=${key}`)
            }}
            items={TabItems} />

        </div>
        <div className='row f-rest o-hide edu-list'>
          <div className='row w-100 h-100 o-hide v-start'>
            <div className='row f-rest h-100 o-hide'>
              <DocumentTable
                noSelection
                emptyMessage="No Assessment yet"
                Columns={getColumns()}
                rows={evaluations ? evaluations.rows : null}
                totalCount={evaluations ? evaluations.totalCount : 0}
                onSearch={handleSearch}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      {
        Boolean(state.selected && evaluation) &&
        <EvaluationAssementor
          evaluation={evaluation}
          readOnly
          onClose={() => {
            setState((_) => ({ ..._, selected: null }))
          }} />
      }
      {state.showDialog === 'upload' ?
        <UploadEvaluation
          onClose={() => handleDialog('')}
        />
        : Boolean(state.showDialog) ?
          <ActivateDialog
            {...state.showDialog}
            onClose={() => handleAction('', '')}
          />
          : null
      }
    </div>
  )
}